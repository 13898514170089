import { InvisibleSmartCaptcha } from "@yandex/smart-captcha";
import PropTypes from "prop-types";

export const Captcha = ({
  onChange,
  visible,
  onChallengeHidden,
  onChallengeVisible,
  captchaKey,
}) => {
  return (
    <InvisibleSmartCaptcha
      key={captchaKey}
      sitekey={process.env.REACT_APP_YANDEX_CAPTCHA_KEY}
      onSuccess={onChange}
      onChallengeHidden={onChallengeHidden}
      onChallengeVisible={onChallengeVisible}
      visible={visible}
    />
  );
};

Captcha.propTypes = {
  captchaKey: PropTypes.number,
  visible: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onChallengeHidden: PropTypes.func.isRequired,
  onChallengeVisible: PropTypes.func.isRequired,
};
