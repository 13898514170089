// export const detailArrayBasic = [
//   { name: "Товаров", offered: true },
//   { name: "Категорий", offered: true },
//   { name: "Брендов", offered: true },
//   { name: "Продавцов", offered: false },
//   { name: "Размеров", offered: false },
//   { name: "Цветов", offered: false },
//   { name: "Складов", offered: false },
//   { name: "devider", offered: false },
//   { name: "Новинки", offered: false },
//   { name: "devider", offered: false },
//   { name: "Тренды", offered: false },
//   { name: "Ценовая сегментация", offered: false },
//   { name: "devider", offered: false },
//   { name: "Позиции в категориях", offered: false },
//   { name: "Поисковые запросы", offered: false },
//   { name: "Изменение цен", offered: true },
//   { name: "Рейтинги и отзывы", offered: true },
//   { name: "devider", offered: false },
//   { name: "Расширение для браузера", offered: true },
//   { name: "devider", offered: false },
//   { name: "Аналитика рекламы", offered: false },
//   { name: "devider", offered: false },
//   { name: "Аналитика контента", offered: true },
//   { name: "devider", offered: false },
//   { name: "Экспорт в файлы", offered: false },
//   { name: "devider", offered: false },
//   { name: <span>Индивидуальное обучение <br/>с менеджером</span>, offered: false },
// ];

export const detailArrayBasic = [
  {
    label: "Ниши и конкуренты",
    expandable: true,
    details: [
      { name: "Аналитика по Товарам", offered: true },
      { name: "Аналитика по Продавцам", offered: true },
      { name: "Аналитика по Категориям", offered: true },
      { name: "Аналитика по Складам и Регионам", offered: true },
      { name: "Аналитика по Цветам и Размерам", offered: true },
      { name: "Трекер Позиций", offered: true },
      { name: "Ценовые Сегменты", offered: true },
      { name: "Динамика и Тренды", offered: true },
      { name: "Аналитика Новинок", offered: true },
      { name: "Потенциал и Упущенные заказы", offered: true },
      { name: "Похожие Товары", offered: true },
      { name: "Изменения Товаров", offered: true },
      { name: "Качество Контента", offered: true },
      { name: "Аналитика по Брендам", offered: true },
      { name: "Избранное", offered: true },
    ],
  },
  {
    label: "Расширение для браузера",
    expandable: false,
    description: "Всегда под рукой",
    details: [],
  },
  {
    label: "SEO-продвижение",
    expandable: true,
    details: [
      { name: "Подбор ключевых слов", offered: false },
      { name: "Подбор слов по подсказкам", offered: false },
      { name: "Сравнение товаров", offered: false },
      { name: "Трекер позиций", offered: false },
      { name: "Мониторинг товаров", offered: false },
      { name: "Позиции товаров", offered: false },
      { name: "Умный подбор слов", offered: false },
      { name: "Умное описание товаров", offered: false },
    ],
  },

  // {
  //   label: "Управление продажами",
  //   expandable: true,
  //   details: [
  //     { name: "Ежедневная динамика", offered: false },
  //     { name: "Аналитика по товарам", offered: false },
  //     { name: "Аналитика заказов возвратов и продаж", offered: false },
  //     { name: "Аналитика финансовых показателей", offered: false },
  //     { name: "Аналитика по складам", offered: false },
  //     { name: "Аналитика по размерам", offered: false },
  //     { name: "Планирование поставок", offered: false },
  //     { name: "Потенциал и Упущенные заказы", offered: false },
  //     { name: "ABC Анализ", offered: false },
  //     { name: "Трекер позиций", offered: false },
  //     { name: "Похожие товары", offered: false },
  //     { name: "Управление ценами", offered: false },
  //     { name: "Товары требующие внимания", offered: false },
  //     { name: "Панель экспресс анализа бизнеса", offered: false },
  //     { name: "Еженедельные отчеты", offered: false },
  //   ],
  // },
  // {
  //   label: 'Реклама',
  //   expandable: true,
  //   details: [
  //     { name: "Проверка рекламных ставок", offered: true },
  //   ]
  // }
];

export const detailArrayEnterprise = [
  {
    label: "Ниши и конкуренты",
    expandable: true,
    details: [
      { name: "Аналитика по Товарам", offered: true },
      { name: "Аналитика по Продавцам", offered: true },
      { name: "Аналитика по Категориям", offered: true },
      { name: "Аналитика по Складам и Регионам", offered: true },
      { name: "Аналитика по Цветам и Размерам", offered: true },
      { name: "Трекер Позиций", offered: true },
      { name: "Ценовые Сегменты", offered: true },
      { name: "Динамика и Тренды", offered: true },
      { name: "Аналитика Новинок", offered: true },
      { name: "Потенциал и Упущенные заказы", offered: true },
      { name: "Похожие Товары", offered: true },
      { name: "Изменения Товаров", offered: true },
      { name: "Качество Контента", offered: true },
      { name: "Аналитика по Брендам", offered: true },
      { name: "Избранное", offered: true },
    ],
  },
  {
    label: "Расширение для браузера",
    expandable: false,
    description: "Всегда под рукой",
    details: [],
  },
  {
    label: "SEO-продвижение",
    expandable: true,
    details: [
      { name: "Подбор ключевых слов", offered: true },
      { name: "Подбор слов по подсказкам", offered: true },
      { name: "Сравнение товаров", offered: true },
      { name: "Трекер позиций", offered: true },
      { name: "Мониторинг товаров", offered: true },
      { name: "Позиции товаров", offered: true },
      { name: "Умный подбор слов", offered: true },
      { name: "Умное описание товаров", offered: true },
    ],
  },

  // {
  //   label: 'Управление продажами',
  //   expandable: true,
  //   details: [
  //     { name: "Ежедневная динамика", offered: true },
  //     { name: "Аналитика по товарам", offered: true },
  //     { name: "Аналитика заказов возвратов и продаж", offered: true },
  //     { name: "Аналитика финансовых показателей", offered: true },
  //     { name: "Аналитика по складам", offered: true },
  //     { name: "Аналитика по размерам", offered: true },
  //     { name: "Планирование поставок", offered: true },
  //     { name: "Потенциал и Упущенные заказы", offered: true },
  //     { name: "ABC Анализ", offered: true },
  //     { name: "Трекер позиций", offered: true },
  //     { name: "Похожие товары", offered: true },
  //     { name: "Управление ценами", offered: true },
  //     { name: "Товары требующие внимания", offered: true },
  //     { name: "Панель экспресс анализа бизнеса", offered: true },
  //     { name: "Еженедельные отчеты", offered: true },
  //   ]
  // },
  // {
  //   label: 'Реклама',
  //   expandable: true,
  //   details: [
  //     { name: "Проверка рекламных ставок", offered: true },
  //   ]
  // }
];

export const detailArrayExtWB = [
  {
    label: "Аналитика продаж",
    expandable: false,
    details: [
      { name: "Товаров", offered: true },
      { name: "Категорий", offered: true },
      { name: "Брендов", offered: true },
      { name: "Продавцов", offered: true },
      { name: "Размеров", offered: true },
      { name: "Цветов", offered: true },
      { name: "Складов", offered: true },
    ],
  },
];

export const detailArrayExpert = [
  { name: "Товаров", offered: true },
  { name: "Категорий", offered: true },
  { name: "Брендов", offered: true },
  { name: "Продавцов", offered: true },
  { name: "Размеров", offered: true },
  { name: "Цветов", offered: true },
  { name: "Складов", offered: false },
  { name: "devider", offered: false },
  { name: "Новинки", offered: true },
  { name: "devider", offered: false },
  { name: "Тренды", offered: true },
  { name: "Ценовая сегментация", offered: true },
  { name: "devider", offered: false },
  { name: "Позиции в категориях", offered: true },
  { name: "Поисковые запросы", offered: true },
  { name: "Изменение цен", offered: true },
  { name: "Рейтинги и отзывы", offered: true },
  { name: "devider", offered: false },
  { name: "Расширение для браузера", offered: true },
  { name: "devider", offered: false },
  { name: "Аналитика рекламы", offered: false },
  { name: "devider", offered: false },
  { name: "Аналитика контента", offered: true },
  { name: "devider", offered: false },
  { name: "Экспорт в файлы", offered: true },
];

// export const detailArrayEnterprise = [
//   { name: "Товаров", offered: true },
//   { name: "Категорий", offered: true },
//   { name: "Брендов", offered: true },
//   { name: "Продавцов", offered: true },
//   { name: "Размеров", offered: true },
//   { name: "Цветов", offered: true },
//   { name: "Складов", offered: true },
//   { name: "devider", offered: false },
//   { name: "Новинки", offered: true },
//   { name: "devider", offered: false },
//   { name: "Тренды", offered: true },
//   { name: "Ценовая сегментация", offered: true },
//   { name: "devider", offered: false },
//   { name: "Позиции в категориях", offered: true },
//   { name: "Поисковые запросы", offered: true },
//   { name: "Изменение цен", offered: true },
//   { name: "Рейтинги и отзывы", offered: true },
//   { name: "devider", offered: false },
//   { name: "Расширение для браузера", offered: true },
//   { name: "devider", offered: false },
//   { name: "Аналитика рекламы", offered: true },
//   { name: "devider", offered: false },
//   { name: "Аналитика контента", offered: true },
//   { name: "devider", offered: false },
//   { name: "Экспорт в файлы", offered: true },
//   { name: "devider", offered: false },
//   { name: <span style={{ fontWeight: 800 }}>Индивидуальное обучение <br/>с менеджером</span>, offered: true },
// ];

export const detailArrayBasicMob = [
  { name: "Товаров", offered: true },
  { name: "Категорий", offered: true },
  { name: "Брендов", offered: true },
  { name: "devider", offered: false },
  { name: "Изменение цен", offered: true },
  { name: "Рейтинги и отзывы", offered: true },
  { name: "devider", offered: false },
  { name: "Расширение для браузера", offered: true },
  { name: "devider", offered: false },
  { name: "Аналитика контента", offered: true },
];

export const detailArrayExpertMob = [
  { name: "Товаров", offered: true },
  { name: "Категорий", offered: true },
  { name: "Брендов", offered: true },
  { name: "Продавцов", offered: true },
  { name: "Размеров", offered: true },
  { name: "Цветов", offered: true },
  { name: "devider", offered: false },
  { name: "Новинки", offered: true },
  { name: "devider", offered: false },
  { name: "Тренды", offered: true },
  { name: "Ценовая сегментация", offered: true },
  { name: "devider", offered: false },
  { name: "Позиции в категориях", offered: true },
  { name: "Поисковые запросы", offered: true },
  { name: "Изменение цен", offered: true },
  { name: "Рейтинги и отзывы", offered: true },
  { name: "devider", offered: false },
  { name: "Расширение для браузера", offered: true },
  { name: "devider", offered: false },
  { name: "Аналитика контента", offered: true },
  { name: "devider", offered: false },
  { name: "Экспорт в файлы", offered: true },
];

export const detailArrayEnterpriseMob = [
  { name: "Товаров", offered: true },
  { name: "Категорий", offered: true },
  { name: "Брендов", offered: true },
  { name: "Продавцов", offered: true },
  { name: "Размеров", offered: true },
  { name: "Цветов", offered: true },
  { name: "Складов", offered: true },
  { name: "devider", offered: false },
  { name: "Новинки", offered: true },
  { name: "devider", offered: false },
  { name: "Тренды", offered: true },
  { name: "Ценовая сегментация", offered: true },
  { name: "devider", offered: false },
  { name: "Позиции в категориях", offered: true },
  { name: "Поисковые запросы", offered: true },
  { name: "Изменение цен", offered: true },
  { name: "Рейтинги и отзывы", offered: true },
  { name: "devider", offered: false },
  { name: "Расширение для браузера", offered: true },
  { name: "devider", offered: false },
  { name: "Аналитика рекламы", offered: true },
  { name: "devider", offered: false },
  { name: "Аналитика контента", offered: true },
  { name: "devider", offered: false },
  { name: "Экспорт в файлы", offered: true },
];
