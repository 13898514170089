import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 100%;
  ${(props) => !props.head && "max-width: 1300px;"};
  margin: 0 auto;
  padding: 40px;
  box-sizing: border-box;
  ${(props) => props.head && "padding-bottom: 56px;"};
  ${(props) =>
    props.head &&
    "padding-left: 120px; padding-right: 120px; margin: 0; padding-top:0"};

  @media (max-width: 720px) {
    padding: 0 25px;
    ${(props) => props.head && "padding-bottom: 36px;"}
  }

  @media (max-width: 480px) {
    padding: 0;
    ${(props) => props.head && "padding-bottom: 22px;"}
    ${(props) => props.usePadding && "padding-top: 26px;"}
  }
`;
