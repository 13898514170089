import styled from "styled-components";

export const StyledCardContainer = styled.div`
  display: flex;
  flex-grow: 1;
  position: relative;
  flex-direction: column;
  background: #f9f9f9;
  border-radius: 12px;
  padding: 40px;
  box-sizing: border-box;
  margin-bottom: 40px;
  width: 100%;
  transition: 0.3s;
  ${(props) =>
    props.popup &&
    `&:hover {
    box-shadow: 0px 4px 40px rgba(39, 150, 83, 0.25);
    background-color: white;
    cursor: pointer;
  }`}

  ${(props) =>
    props.isInner &&
    `
      height: fit-content;
    margin-right: 40px;
  `};

  @media (max-width: 740px) {
    padding: 30px;
    margin-bottom: 20px;
  }
  @media (max-width: 640px) {
    padding: 26px;
    ${(props) => (props.height ? `height: ${props.height};` : "height: 450px;")}

    margin-bottom: 16px;
  }
  @media (max-width: 480px) {
    padding: 26px;
    margin-bottom: 16px;
  }
`;
export const StyledIconContainer = styled.div`
  width: 44px;
  height: 44px;
  box-sizing: border-box;
  background: #00745f;
  box-shadow: 0 5px 25px rgba(14, 136, 67, 0.41);
  border-radius: 100px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`;
export const StyledTitleContainer = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 37px;
  color: #1f202a;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  p {
    white-space: nowrap;
    &::after {
      content: "›";
      opacity: 0;
      margin-left: 5px;
      color: #289e5b;
    }
  }
  ${(props) =>
    props.isInner &&
    `
    font-size: 20px;
    line-height: 26px;
    p {
      white-space: normal !important;
    }
  `}
  ${(props) =>
    props.popup &&
    `${StyledCardContainer}:hover &&{
    color: #213A2B;
    transition: 0.3s;
    p {
      white-space: nowrap;
     /* &::after {
        content: "›";
        opacity: 1;
        margin-left: 5px;
        color: #213A2B;
      }*/
    }
  }`};

  @media (max-width: 640px) {
    font-size: 20px;
    padding-bottom: 24px;
    margin-bottom: 8px;
    ${StyledCardContainer} & {
    //  p {
    //    &::after {
    //      content: "›";
    //      opacity: 1;
    //      margin-left: 5px;
    //      color: #1f202a;
    //    }
    //  }
    //}
    //p {
    //  &::after {
    //    content: "›";
    //    opacity: 1;
    //    margin-left: 5px;
    //    color: #1f202a;
    //  }
    //}
  }
`;
export const StyledTextContainer = styled.div`
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #213a2b;
`;
export const StyledSubTextContainer = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #395243;
`;

export const StyledButtonContainer = styled.div`
  margin-top: 27px;
  display: flex;
  justify-content: center;
  button {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #213a2b;
  }
  @media (max-width: 480px) {
    display: none;
  }
`;

export const StyledScreenshotLink = styled.div`
  position: absolute;
  right: 40px;
  padding: 10px 16px;
  border-radius: 100px;
  background-color: #f1fcf6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00745f;
  width: fit-content !important;
  top: 40px;
`;
