import { PURPOSES } from "./constants";
import { StyledPurposeBlock } from "./PurposesStyle";

const Purposes = () => {
  return (
    <div className="section bg-white">
      <PurposesBlock id={"newbies"} />
      <PurposesBlock id={"oldies"} />
    </div>
  );
};

export default Purposes;

const PurposesBlock = ({ id }) => {
  const { label, items } = PURPOSES[id];
  return (
    <div className="section-inner">
      <h4>{label}</h4>
      <div className="grid lg:grid-cols-3 gap-10">
        {items.map(({ title, description, icon }) => (
          <StyledPurposeBlock
            key={title}
            //   className="rounded-3xl shadow-lg shadow-[#27965333] p-10"
          >
            <div className="flex flex-col gap-4">
              <span className="-ml-6 -mt-4 ">{icon}</span>
              <div className="lg:divide-y -mt-6 lg:mt-0 divide-[#0000000F]">
                <h5>{title}</h5>
                <p>{description}</p>
              </div>
            </div>
          </StyledPurposeBlock>
        ))}
      </div>
    </div>
  );
};
