import React from "react";
import { StyledA, StyledLink } from "./NavLinkStyle";

export const NavLink = ({
  children,
  link,
  active,
  isA,
  noMargin,
  style,
  onClick,
}) => {
  if (isA) {
    return (
      <StyledA $active={active} href={link} style={style}>
        {children}
      </StyledA>
    );
  }
  return (
    <StyledLink
      $active={active}
      to={link}
      $noMargin={noMargin}
      style={style}
      onClick={onClick}
    >
      {children}
    </StyledLink>
  );
};
