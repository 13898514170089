import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledLink = styled(Link)`
  border: none;
  background-color: #fff;
  padding: 12px 0;
  font-family: "Sofia Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: rgba(33, 58, 43, 0.4);
  box-sizing: border-box;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
  &:hover {
    color: #213a2b;
    border-bottom: 2px solid #213a2b;
  }
  ${(props) =>
    props.$active
      ? `
    color: #213A2B;
    border-bottom: 2px solid #213A2B;
     @media (max-width: 640px) {
     border-bottom-width: 1px;
     }
    `
      : `
    border-bottom: 1px solid #EAEAEA;
    `}
  ${(props) =>
    props.$noMargin
      ? `
    margin-right: 0;
    `
      : `
    margin-right: 40px;
    `}

    @media (max-width: 640px) {
    padding: 16px 0;

    border-bottom-size: 2px;
  }
`;

export const StyledA = styled.a`
  border: none;
  background-color: #fff;
  padding: 12px 0;
  font-family: "Sofia Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: rgba(33, 58, 43, 0.4);
  margin-right: 40px;
  box-sizing: border-box;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
  &:hover {
    color: #213a2b;
    border-bottom: 2px solid #213a2b;
  }
  ${(props) =>
    props.$active
      ? `
    color: #213A2B;
    border-bottom: 2px solid #213A2B;
    `
      : `
    border-bottom: 1px solid #EAEAEA;
    `}
`;
