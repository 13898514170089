import React from "react";
import { StyledButton } from "./GreenButtonStyle";

export const GreenButton = ({ children, link, tag, tag2, style }) => {
  return (
    <StyledButton
      onClick={() => {
        typeof tag === "function" && tag();
        typeof tag2 === "function" && tag2();
        link && window.open(link);
      }}
      style={style}
    >
      {children}
    </StyledButton>
  );
};
