import React from "react";
import styled from "styled-components";
import { PrimaryButton } from "../../elements";

const StyledExtBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 29px 16px 16px;
  background-color: rgba(123, 75, 152, 0.08);
  border-radius: 100px;
  @media (max-width: 510px) {
    padding: 16px;
    border-radius: 12px;
  }
`;

const StyledExtLink = styled.a`
  display: flex;
  text-decoration: none;
  @media (max-width: 510px) {
    flex-direction: column;
    width: 100%;
    div:last-child {
      align-items: center;
      margin-top: 20px;
    }
  }
`;

const ExtSetupBlock = () => {
  return (
    <div style={{ borderTop: "1px solid rgba(0,0,0,0.06)", paddingTop: 12 }}>
      <StyledExtBlock>
        <StyledExtLink
          target="_blank"
          rel="noreferrer"
          href={
            "https://chrome.google.com/webstore/detail/wecheck/nbhkkkflcfheikkjmcphbfhbleblccgl"
          }
          style={{ textDecoration: "none", display: "flex" }}
          onClick={(e) => e.stopPropagation()}
        >
          <PrimaryButton>Скачать расширение</PrimaryButton>
          <div
            style={{
              marginLeft: 12,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <span style={{ display: "block", fontSize: 16, color: "#213A2B" }}>
              Супер-цена 🔥🔥🔥
            </span>
            <span style={{ display: "block", fontSize: 20 }}>
              <span style={{ color: "#213A2B", fontWeight: 700 }}>
                Всего 790₽
              </span>
              <span
                style={{ color: "#858585", fontWeight: 400, marginLeft: 5 }}
              >
                в месяц
              </span>
            </span>
          </div>
        </StyledExtLink>
      </StyledExtBlock>
    </div>
  );
};

export default ExtSetupBlock;
