import { OPPORTUNITIES } from "./contants";
import {
  StyledOpportinityBlock,
  StyledOpportinityHeader,
} from "./OpportunitiesStyle";

const OpportunitiesSection = () => {
  return (
    <div id="tools" className="section bg-white">
      <h4>Возможности</h4>
      <div className="grid lg:grid-cols-2 gap-5 lg:gap-10">
        {OPPORTUNITIES.map(({ header, title, description }) => {
          return (
            <StyledOpportinityBlock
              key={title}
              //   className="rounded-3xl shadow-lg shadow-[#27965333] p-10"
            >
              <div className="flex flex-col gap-3">
                <div className="lg:mb-5">
                  <StyledOpportinityHeader>{header}</StyledOpportinityHeader>
                </div>
                <div className=" divide-y divide-[#0000000F]">
                  <h3>{title}</h3>
                  <p>{description}</p>
                </div>
              </div>
            </StyledOpportinityBlock>
          );
        })}
      </div>
    </div>
  );
};

export default OpportunitiesSection;
