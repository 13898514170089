const CreditCardIcon = (props) => {
  return (
    <svg
      width="94"
      height="94"
      {...props}
      viewBox="0 0 95 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_9151_31954)">
        <circle cx="47.333" cy="42" r="22" fill="#00745F" />
      </g>
      <path
        d="M36.5 39H58.5M39.2 51H55.8C57.2912 51 58.5 49.7912 58.5 48.3V35.7C58.5 34.2088 57.2912 33 55.8 33H39.2C37.7088 33 36.5 34.2088 36.5 35.7V48.3C36.5 49.7912 37.7088 51 39.2 51Z"
        stroke="white"
      />
      <path
        d="M40 36C40 36.2761 39.7761 36.5 39.5 36.5C39.2239 36.5 39 36.2761 39 36C39 35.7239 39.2239 35.5 39.5 35.5C39.7761 35.5 40 35.7239 40 36ZM43 36C43 36.2761 42.7761 36.5 42.5 36.5C42.2239 36.5 42 36.2761 42 36C42 35.7239 42.2239 35.5 42.5 35.5C42.7761 35.5 43 35.7239 43 36ZM46 36C46 36.2761 45.7761 36.5 45.5 36.5C45.2239 36.5 45 36.2761 45 36C45 35.7239 45.2239 35.5 45.5 35.5C45.7761 35.5 46 35.7239 46 36Z"
        fill="white"
        stroke="white"
      />
      <defs>
        <filter
          id="filter0_d_9151_31954"
          x="0.333008"
          y="0"
          width="94"
          height="94"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="12.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0555556 0 0 0 0 0.533333 0 0 0 0 0.262053 0 0 0 0.41 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9151_31954"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9151_31954"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default CreditCardIcon;
