import { gtag } from "ga-gtag";
import { GA_ID, YA_ID } from "./consts";

const UTM_STORAGE_LS = "we_utm_params";

export function saveUTMParametersToLocalStorage() {
  const urlParams = new URLSearchParams(window.location.search);
  const utmKeys = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
  ];

  const existingUTMs = {};

  utmKeys.forEach((key) => {
    if (urlParams.has(key) && !existingUTMs[key]) {
      existingUTMs[key] = urlParams.get(key);
    }
  });

  if (existingUTMs && Object.values(existingUTMs).length > 0) {
    localStorage.setItem(UTM_STORAGE_LS, JSON.stringify(existingUTMs));
  }
}

export function clearUTMParametersFromLocalStorage() {
  localStorage.removeItem(UTM_STORAGE_LS);
}

export function getUTMParametersFromLocalStorage() {
  let utmParams = JSON.parse(localStorage.getItem(UTM_STORAGE_LS)) || {};

  return utmParams;
}

export async function getUTMParametersFromLocalStorageAsync(
  includeUserIds = false
) {
  let utmParams = JSON.parse(localStorage.getItem(UTM_STORAGE_LS)) || {};

  if (includeUserIds) {
    try {
      const gaClientId = await getGA4ClientId();
      if (gaClientId) {
        utmParams.ga_client_id = gaClientId;
      }

      const ymClientId = await getYmUid();
      if (ymClientId) {
        utmParams.ya_client_id = ymClientId;
      }
    } catch (error) {
      console.error("Error fetching client IDs:", error);
    }
  }

  return utmParams;
}

export function addUTMParametersToUrl(url, divider = "/?") {
  const parameters = getUTMParametersFromLocalStorage();
  if (Object.values(parameters).length > 0) {
    return (
      url +
      divider +
      Object.entries(parameters)
        .flatMap(([key, value]) => [value].flat().map((v) => [key, v]))
        .map((it) => it.join("="))
        .join("&")
    );
  }

  return url;
}

export async function addUTMParametersToUrlForSubmit(url, divider = "/?") {
  const parameters = await getUTMParametersFromLocalStorageAsync(true);
  if (Object.values(parameters).length > 0) {
    const queryString = new URLSearchParams(parameters).toString();
    return url + divider + `utm_params=${encodeURIComponent(queryString)}`;
  }
  return url;
}

export function getYmUid() {
  return new Promise((resolve) => {
    try {
      window.ym(YA_ID, "getClientID", function (clientId) {
        resolve(clientId || null);
      });
    } catch (error) {
      console.error("Error getting YA client ID:", error);
      resolve(null);
    }
  });
}

export function getGA4ClientId() {
  if (typeof gtag !== "function") {
    return Promise.resolve(null);
  }

  return new Promise((resolve) => {
    try {
      gtag("get", GA_ID, "client_id", (clientId) => {
        resolve(clientId || null);
      });
    } catch (error) {
      console.error("Error getting GA4 client ID:", error);
      resolve(null);
    }
  });
}
