import React from "react";
import { Container } from "../../elements";
import { HeadSectionComponent } from "../HeadSection/HeadSectionComponent";
// import { StyledHeadSection } from "./HeaderSectionStyle";

export const HeaderSection = () => {
  return (
    //<StyledHeadSection>
    <div className="relative overflow-hidden bottom-gradient">
      <Container head={true}>
        <HeadSectionComponent />
      </Container>
    </div>
    // </StyledHeadSection>
  );
};
