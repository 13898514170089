import styled from "styled-components";
import { StyledCardContainer } from "../AnalyticCard/AnalyticCardStyled";

export const StyledSection = styled.section`
  padding-bottom: 2px;
  border-radius: 80px;
  z-index: 2;
  background: #fff;
`;

export const StyledContent = styled.div`
  display: flex;
  gap: 40px;
  div${StyledCardContainer} p {
    white-space: normal;
  }
  @media (max-width: 960px) {
    flex-direction: column;
    div${StyledCardContainer} {
      width: 100%;
    }
  }
  @media (max-width: 640px) {
    gap: 0;
  }
`;

export const StyledTitle = styled.h4`
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 50px;
  letter-spacing: -0.3px;
  color: #213a2b;
  margin: 100px 0 80px;
  @media (max-width: 870px) {
    font-size: 36px;
    line-height: 37px;
    text-align: center;
    margin: 60px 0 32px;
  }
  @media (max-width: 640px) {
    font-size: 24px;
  }
`;

export const StyledAlertContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #e7ebeb;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 20px 50px;
  margin-bottom: 42px;
  cursor: pointer;
  @media (max-width: 910px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 640px) {
    padding: 20px;
    margin-bottom: 76px;
  }
`;
export const StyledAlert = styled.div`
  border: 1px solid #ff0000;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 2px 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 173.81%;
  color: #ff0000;
  @media (max-width: 910px) {
    margin-bottom: 15px;
  }
`;
export const StyledAlertText = styled.p`
  margin: 0px 15px 0 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 173.81%;
  color: #6e6e6e;
  @media (max-width: 910px) {
    margin: 0 0 20px 0;
  }
`;
export const StyledAlertButton = styled.p`
  justify-self: flex-end;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 173.81%;
  color: #00745f;
  white-space: nowrap;
  cursor: pointer;
  transition: 0.3s;
`;

export const StyledHoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  opacity: 0;
  background-color: #00745f;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: 0.3s;
  p {
    color: #f9f9f9;
    text-align: center;
    font-weight: 900;
    font-size: 30px;
    line-height: 147.07%;
  }

  ${StyledAlertContainer}:hover & {
    opacity: 1;
  }
`;
