const PointerIcon = (props) => {
  return (
    <svg
      width="94"
      height="94"
      {...props}
      viewBox="0 0 95 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_9151_31930)">
        <circle cx="47.667" cy="42" r="22" fill="#00745F" />
      </g>
      <path
        d="M47.0956 33C45.9267 33 45.3813 33.6681 45.3813 34.7419V44.8125L42.6456 42.4475C41.8954 41.6828 40.9313 41.5381 40.1462 42.254C39.4135 42.9373 39.5771 43.9428 40.2575 44.6587L44.5671 50.2245C44.9459 50.7136 45.5298 51 46.1485 51L52.7762 51C53.7371 51 54.5622 50.3166 54.7412 49.3725L55.6016 44.8337C55.9331 43.3856 54.9735 41.9376 53.4208 41.5796L48.8098 40.3125V34.7419C48.8098 33.6681 48.247 33 47.0956 33Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_9151_31930"
          x="0.666992"
          y="0"
          width="94"
          height="94"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="12.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0555556 0 0 0 0 0.533333 0 0 0 0 0.262053 0 0 0 0.41 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9151_31930"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9151_31930"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default PointerIcon;
