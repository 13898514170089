import styled from "styled-components";

export const StyledPurposeBlock = styled.div`
  gap: 32px;
  border-radius: 32px;
  padding: 40px;
  background: #f9f9f9;

  @media (max-width: 640px) {
    padding: 26px;
    gap: 16px;
  }

  h5 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0px;
    color: #1f202a;
    margin-bottom: 20px;

    @media (max-width: 640px) {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 10px;
    }
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0px;
    color: #395243;
    padding-top: 20px;
    @media (max-width: 640px) {
      font-size: 16px;
      line-height: 24px;
      padding-top: 10px;
    }
  }
`;
