import { useEffect, useState } from "react";
import ScreenshotsIcon from "../../assets/icons/ScreenshotsIcon";
import Img1 from "../../assets/img/popup-img/ext/01_XL.jpg";
import Img2 from "../../assets/img/popup-img/ext/02_XL.jpg";
import Img3 from "../../assets/img/popup-img/ext/03_XL.jpg";
import Img4 from "../../assets/img/popup-img/ext/04_XL.jpg";
import { StyledPrimaryButton } from "../../elements/PrimaryButton/PrimaryButtonStyle";
import CarouselModal from "../Carousel/modal";
import { ExtScreenshotsStyledButton } from "./ExtScreenshotsStyledButton";

const images = [Img1, Img2, Img3, Img4];
const preloadImage = (url) => {
  const img = new Image();
  img.src = url;
};

const ExtScreenshotsButton = ({ children }) => {
  const [open, setIsOpen] = useState(false);

  useEffect(() => {
    images.forEach((imageUrl) => preloadImage(imageUrl));
  });
  return (
    <StyledPrimaryButton>
      <CarouselModal
        title={"Скриншоты расширения"}
        open={open}
        images={images}
        closeModal={() => setIsOpen(false)}
      />
      <ExtScreenshotsStyledButton
        onClick={() => setIsOpen(true)}
        className="mb-2"
      >
        <div className="items-center flex flex-col lg:flex-row">
          {children}
          <ScreenshotsIcon className={"lg:mb-[-14px] lg:-mt-[34px]"} />
        </div>
      </ExtScreenshotsStyledButton>
    </StyledPrimaryButton>
  );
};

export default ExtScreenshotsButton;
