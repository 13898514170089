import gtag from "ga-gtag";
import { useState } from "react";
import ym from "react-yandex-metrika";
import { PrimaryButton } from "../../elements";
import StartNowSideIcon from "./assets/StartNowSideIcon";

const StartNowSection = () => {
  const [openDemo, setOpenDemo] = useState(false);

  const closeModalDemo = () => {
    setOpenDemo(false);
  };
  return (
    <div className="bottom-gradient">
      <div className="section">
        <div className="block lg:hidden mb-5">
          <StartNowSideIcon key={"mobile"} />
        </div>
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center space-y-4 sm:space-y-0">
          <div>
            <div className="pb-5 lg:pb-10  font-bold text-2xl lg:text-5xl text-[#213A2B] lg:leading-[56px]">
              Начните увеличивать
              <br className="hidden lg:block" /> прибыль{" "}
              <span className="text-[#017560]">уже сегодня</span>
              <svg
                width="300"
                height="19"
                viewBox="0 0 338 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="lg:ml-[200px] hidden lg:block"
              >
                <path
                  d="M3.24665 15.9034C72.1988 10.0299 140.993 4.26546 209.182 3.42063C246.277 2.96105 284.449 3.56645 317.031 7.74011C323.048 8.51082 329.934 9.38907 334.048 11.0336"
                  stroke="#00745F"
                  strokeWidth="6"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <PrimaryButton
              onClick={() => setOpenDemo(true)}
              open={openDemo}
              closeModal={() => closeModalDemo()}
              tag={() => {
                gtag("event", "form_send", {
                  form: "dostup_head",
                });
                gtag("event", "form_send", {
                  form: "24_hour",
                });
                gtag("event", "form_send", {
                  form: "all_leads",
                });
              }}
              tag2={() => {
                ym("reachGoal", "form_dostup_head");
                ym("reachGoal", "24_hour");
                ym("reachGoal", "all_leads");
              }}
            >
              Получить доступ
            </PrimaryButton>
          </div>
          <div className="hidden lg:block">
            <StartNowSideIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartNowSection;
