import CreditCardIcon from "../../assets/icons/Green/CreditCardIcon";
import GearIcon from "../../assets/icons/Green/GearIcon";
import PointerIcon from "../../assets/icons/Green/PointerIcon";
import PricetagIcon from "../../assets/icons/Green/PricetagIcon";
import RoubleIcon from "../../assets/icons/Green/RoubleIcon";
import StrategyIcon from "../../assets/icons/Green/StrategyIcon";

export const PURPOSES = {
  newbies: {
    label: "Для тех, кто планирует выйти на маркетплейс",
    items: [
      {
        icon: <GearIcon />,
        title: "Оценить рыночный потенциал",
        description:
          "Понять, какие товары востребованы, какие ниши свободны и как выглядит конкурентная среда.",
      },
      {
        icon: <StrategyIcon />,
        title: "Определить стратегию выхода",
        description:
          "Изучить цены, акции и стратегии топ-продавцов для выстраивания эффективной ценовой политики.",
      },
      {
        icon: <PointerIcon />,
        title: "Анализировать конкурентов заранее",
        description:
          "Узнать, кто уже присутствует в выбранной категории и какие у них сильные и слабые стороны.",
      },
    ],
  },
  oldies: {
    label: "Для тех, кто уже продает на маркетплейсе",
    items: [
      {
        icon: <PricetagIcon />,
        title: "Увеличить продажи за счет оптимизации цен и стратегий",
        description:
          "Регулярно анализировать конкурентов и гибко реагировать на изменения рынка.",
      },
      {
        icon: <CreditCardIcon />,
        title: "Следить за изменениями карточек конкурентов",
        description:
          "Получать информацию о скидках, акциях и новых стратегиях у конкурентов.",
      },
      {
        icon: <RoubleIcon />,
        title: "Снизить издержки и оптимизировать ассортимент",
        description:
          "Отслеживать эффективность собственных товаров и отказываться от нерентабельных позиций.",
      },
    ],
  },
};
