import styled from "styled-components";
import { ReactComponent as Close } from "../../assets/svg/close-icon.svg";

export const StyledCloseButton = styled(Close)`
  align-self: center;
  cursor: pointer;
  &:hover {
    stroke: #ccc;
  }
`;

export const StyledHeader = styled.header`
  position: sticky;
  top: 0px;
  background: #ffffff;
  z-index: 30;
  box-shadow: inset 0px -1px 0px #eaeaea;
`;

export const StyledHeaderContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: auto;
  box-sizing: border-box;
  padding: 28px 40px;
  margin-bottom: 10px;
  ${(props) => props.head && "padding-bottom: 210px;"};
  @media (max-width: 1180px) {
    padding: 24px 40px;
  }
  @media (max-width: 720px) {
    margin: 0 auto 40px;
    padding: 24px 20px;
    ${(props) => props.head && "padding-bottom: 150px;"}
    margin-bottom: ${(props) => (props.isEcomexpo ? "0px" : "40px")};
  }
  @media (max-width: 480px) {
    margin-bottom: 0;
    padding: 12px 16px;
    ${(props) => props.head && "padding-bottom: 110px;"}
  }
`;
export const StyledNav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const StyledLogoContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 30px;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  margin-right: 30px;
  img:first-child {
    margin-right: 17px;
  }
  @media (max-width: 480px) {
    border-right: none;
  }
`;

export const StyledNonBurgerButtons = styled.div`
  display: none;
  @media (min-width: 640px) {
    display: flex;
    gap: 1.25rem;
  }
`;
export const StyledBurgerButton = styled.div`
  display: block;
  @media (min-width: 640px) {
    display: none;
    height: min-content;
    align-self: center;
    position: relative;
    top: 2px;
  }
`;

export const StyledBurgerMenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  box-shadow: inset 0px -1px 0px #eaeaea;
  padding: 0 22px;
  height: 60px;
  align-items: center;
`;
export const StyledMenuGroup = styled.div`
  @media (max-width: 1180px) {
    display: none;
  }
`;
export const StyledLogButton = styled.div``;

export const StyledBurgerMenu = styled.div`
  h2 {
    margin-bottom: 20px;
  }
  button {
    margin: 0;
  }
  button:last-child {
    margin-top: 20px;
  }
`;

export const StyledMenuContent = styled.div`
  padding: 20px 30px;
`;
