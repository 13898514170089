const RoubleIcon = (props) => {
  return (
    <svg
      width="94"
      height="94"
      {...props}
      viewBox="0 0 95 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_9151_32124)">
        <circle cx="47.667" cy="42" r="22" fill="#00745F" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43 34C43 33.7239 43.2239 33.5 43.5 33.5H50.25C52.8561 33.5 54.9688 35.6127 54.9688 38.2188C54.9688 40.8248 52.8561 42.9375 50.25 42.9375H43.5C43.2239 42.9375 43 42.7136 43 42.4375V34ZM44 34.5V41.9375H50.25C52.3038 41.9375 53.9688 40.2726 53.9688 38.2188C53.9688 36.1649 52.3038 34.5 50.25 34.5H44ZM52.125 46C52.125 46.2761 51.9011 46.5 51.625 46.5H44L44 50.5312C44 50.8074 43.7761 51.0312 43.5 51.0312C43.2239 51.0312 43 50.8074 43 50.5312L43 46.5H41.5C41.2239 46.5 41 46.2761 41 46C41 45.7239 41.2239 45.5 41.5 45.5H51.625C51.9011 45.5 52.125 45.7239 52.125 46Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_9151_32124"
          x="0.666992"
          y="0"
          width="94"
          height="94"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="12.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0555556 0 0 0 0 0.533333 0 0 0 0 0.262053 0 0 0 0.41 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9151_32124"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9151_32124"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default RoubleIcon;
