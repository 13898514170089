const StrategyIcon = (props) => {
  return (
    <svg
      width="94"
      height="94"
      {...props}
      viewBox="0 0 94 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_9151_31936)">
        <circle cx="47.333" cy="42" r="22" fill="#00745F" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.833 32.5C39.4523 32.5 38.333 33.6193 38.333 35V38C38.333 39.3807 39.4523 40.5 40.833 40.5H43.833C45.2137 40.5 46.333 39.3807 46.333 38V35C46.333 33.6193 45.2137 32.5 43.833 32.5H40.833ZM39.333 35C39.333 34.1716 40.0046 33.5 40.833 33.5H43.833C44.6614 33.5 45.333 34.1716 45.333 35V38C45.333 38.8284 44.6614 39.5 43.833 39.5H40.833C40.0046 39.5 39.333 38.8284 39.333 38V35ZM51.833 32.5C50.4523 32.5 49.333 33.6193 49.333 35V38C49.333 39.3807 50.4523 40.5 51.833 40.5H54.833C56.2137 40.5 57.333 39.3807 57.333 38V35C57.333 33.6193 56.2137 32.5 54.833 32.5H51.833ZM50.333 35C50.333 34.1716 51.0046 33.5 51.833 33.5H54.833C55.6614 33.5 56.333 34.1716 56.333 35V38C56.333 38.8284 55.6614 39.5 54.833 39.5H51.833C51.0046 39.5 50.333 38.8284 50.333 38V35ZM38.333 46C38.333 44.6193 39.4523 43.5 40.833 43.5H43.833C45.2137 43.5 46.333 44.6193 46.333 46V49C46.333 50.3807 45.2137 51.5 43.833 51.5H40.833C39.4523 51.5 38.333 50.3807 38.333 49V46ZM40.833 44.5C40.0046 44.5 39.333 45.1716 39.333 46V49C39.333 49.8284 40.0046 50.5 40.833 50.5H43.833C44.6614 50.5 45.333 49.8284 45.333 49V46C45.333 45.1716 44.6614 44.5 43.833 44.5H40.833ZM51.833 43.5C50.4523 43.5 49.333 44.6193 49.333 46V49C49.333 50.3807 50.4523 51.5 51.833 51.5H54.833C56.2137 51.5 57.333 50.3807 57.333 49V46C57.333 44.6193 56.2137 43.5 54.833 43.5H51.833ZM50.333 46C50.333 45.1716 51.0046 44.5 51.833 44.5H54.833C55.6614 44.5 56.333 45.1716 56.333 46V49C56.333 49.8284 55.6614 50.5 54.833 50.5H51.833C51.0046 50.5 50.333 49.8284 50.333 49V46Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_9151_31936"
          x="0.333008"
          y="0"
          width="94"
          height="94"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="12.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0555556 0 0 0 0 0.533333 0 0 0 0 0.262053 0 0 0 0.41 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9151_31936"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9151_31936"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default StrategyIcon;
