import Carousel from ".";
import CloseModal from "../../assets/img/close-modal.png";
import {
  StyledModal,
  StyledModalHeader,
  StyledPopup,
} from "../../elements/ImgPopup/ImgPopupStyle";

const CarouselModal = ({ closeModal, open, title, images }) => {
  return (
    <StyledPopup
      lockScroll={true}
      open={open}
      closeOnDocumentClick={false}
      onClose={closeModal}
      modal
    >
      <StyledModal>
        <StyledModalHeader>
          <p>{title}</p>
          <img onClick={() => closeModal()} src={CloseModal} alt="no img"></img>
        </StyledModalHeader>
        <div className="m-1">
          <Carousel images={images} title={title} />
        </div>
      </StyledModal>
    </StyledPopup>
  );
};

export default CarouselModal;
