import React, { useState } from "react";

import gtag from "ga-gtag";
import { ReactDimmer } from "react-dimmer";
import { useLocation } from "react-router-dom";
import ym from "react-yandex-metrika";
import Menu from "../../assets/img/menu.png";
import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { ReactComponent as Wecheck } from "../../assets/svg/wecheck.svg";
import { GreenButton, PrimaryButton } from "../../elements";
import { NavLink } from "../NavLink/NavLink";
import {
  StyledBurgerButton,
  StyledBurgerMenu,
  StyledBurgerMenuHeader,
  StyledCloseButton,
  StyledHeader,
  StyledHeaderContainer,
  StyledLogButton,
  StyledLogoContainer,
  StyledMenuContent,
  StyledMenuGroup,
  StyledNav,
  StyledNonBurgerButtons,
} from "./HeaderStyle";

import { PrimaryButtonLink } from "../../elements/PrimaryButton/PrimaryButtonLink";
import { useLinks } from "../../hooks";
import TopBanner from "../TopBanner/TopBanner";

export const HeaderComponent = ({ activeTab }) => {
  const {
    // competitorsLink,
    // parsingLink,
    productionHomeLink,
  } = useLinks();

  const [isMenuOpen, setMenu] = useState(false);
  const location = useLocation();
  const isEcomexpo = location.pathname === "/ecomexpo";
  const [openDemo, setOpenDemo] = useState(false);

  const closeModalDemo = () => {
    setOpenDemo(false);
  };

  const handleMenu = () => {
    setMenu((prevState) => !prevState);
  };
  return (
    <StyledHeader>
      <TopBanner />

      <StyledHeaderContainer isEcomexpo={isEcomexpo}>
        <StyledNav>
          <div style={{ display: "flex" }}>
            <StyledLogoContainer
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              className="cursor-pointer"
            >
              <Logo style={{ marginRight: "17px" }} />
              <Wecheck />
            </StyledLogoContainer>
            <StyledMenuGroup>
              <div style={{ display: "inline-flex" }}>
                <NavLink
                  link={"/#tools"}
                  active={activeTab === "market"}
                  noMargin
                >
                  Возможности
                </NavLink>
                <div
                  style={{ width: "12px", borderBottom: "1px solid #EAEAEA" }}
                />
                {/* <NavLink
                  link={"/#extension"}
                  active={activeTab === "extension"}
                  noMargin
                >
                  Расширение
                </NavLink> */}
                <div
                  style={{ width: "12px", borderBottom: "1px solid #EAEAEA" }}
                />
                <NavLink
                  link={"/#rate_section"}
                  active={activeTab === "tariff"}
                >
                  Тарифы
                </NavLink>
              </div>
              {/*<NavButton active={false} link={parsingLink}>*/}
              {/*  Парсинг*/}
              {/*</NavButton>*/}
              {/*<NavButton*/}
              {/*  active={false}*/}
              {/*  link={competitorsLink}*/}
              {/*>*/}
              {/*  Конкуренты*/}
              {/*</NavButton>*/}
              {/* <NavButton
              active={false}
              link={advertisingLink}
            >
              Рекламные ставки
            </NavButton> */}
              {/* <NavButton active={false} link={blogLink}>
                <span className="flex items-center">
                  Блог <ArrowTopRightIcon />
                </span>
              </NavButton> */}
              {/* <NavLink>
              <p>Документы</p> и законодательство
            </NavLink> */}
            </StyledMenuGroup>
          </div>
          <div className="flex gap-5">
            {/*<PhoneChatsHeader />*/}
            <StyledNonBurgerButtons>
              <PrimaryButtonLink
                href={"https://go.wecheck.ru/signup"}
                tag={() => {
                  gtag("event", "form_send", {
                    form: "dostup_head",
                  });
                  gtag("event", "form_send", {
                    form: "24_hour",
                  });
                  gtag("event", "form_send", {
                    form: "all_leads",
                  });
                }}
                tag2={() => {
                  ym("reachGoal", "form_dostup_head");
                  ym("reachGoal", "24_hour");
                  ym("reachGoal", "all_leads");
                }}
                style={{
                  fontSize: 18,
                  padding: "12px 24px",
                }}
              >
                Попробовать бесплатно
              </PrimaryButtonLink>

              <StyledLogButton>
                <GreenButton
                  tag={() => ym("reachGoal", "button_voiti")}
                  tag2={() =>
                    gtag("event", "click_button", {
                      button: "vxod",
                    })
                  }
                  link={productionHomeLink}
                >
                  Войти
                </GreenButton>
              </StyledLogButton>
            </StyledNonBurgerButtons>
            <StyledBurgerButton onClick={handleMenu}>
              <img src={Menu} alt="no img"></img>
            </StyledBurgerButton>
            <ReactDimmer
              isOpen={isMenuOpen}
              exitDimmer={setMenu}
              zIndex={100}
              blur={1.5}
            />
          </div>
        </StyledNav>
      </StyledHeaderContainer>
      <StyledBurgerMenu className={`app-menu ${isMenuOpen ? "menu-open" : ""}`}>
        <StyledBurgerMenuHeader>
          <StyledLogoContainer
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            className="cursor-pointer"
          >
            <Logo style={{ marginRight: "17px" }} />
            <Wecheck />
          </StyledLogoContainer>
          <StyledCloseButton onClick={() => setMenu(false)}></StyledCloseButton>
        </StyledBurgerMenuHeader>
        <StyledMenuContent>
          <div className="flex flex-col mb-5">
            <NavLink link={"/#tools"} active={activeTab === "market"} noMargin>
              Возможности
            </NavLink>
            <NavLink
              link={"/#rate_section"}
              active={activeTab === "tariff"}
              noMargin={true}
            >
              Тарифы
            </NavLink>
          </div>

          <PrimaryButton
            onClick={() => setOpenDemo(true)}
            open={openDemo}
            closeModal={() => closeModalDemo()}
            tag={() => {
              gtag("event", "form_send", {
                form: "dostup_head",
              });
              gtag("event", "form_send", {
                form: "24_hour",
              });
              gtag("event", "form_send", {
                form: "all_leads",
              });
            }}
            tag2={() => {
              ym("reachGoal", "form_dostup_head");
              ym("reachGoal", "24_hour");
              ym("reachGoal", "all_leads");
            }}
            style={{
              fontSize: 18,
              width: "100%",
              alignItems: "flex-start",
            }}
          >
            Попробовать бесплатно
          </PrimaryButton>
          <StyledLogButton>
            <GreenButton
              tag={() => ym("reachGoal", "button_voiti")}
              tag2={() =>
                gtag("event", "click_button", {
                  button: "vxod",
                })
              }
              style={{
                fontSize: 18,
                width: "100%",
                alignItems: "flex-start",
                padding: "16px 24px",
              }}
              link={productionHomeLink}
            >
              Войти
            </GreenButton>
          </StyledLogButton>
        </StyledMenuContent>
      </StyledBurgerMenu>
    </StyledHeader>
  );
};
