import styled from "styled-components";

export const StyledOpportinityBlock = styled.div`
  gap: 20px;
  border-radius: 32px;
  padding: 26px;
  box-shadow: 0px 4px 40px 0px #27965333;

  h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: -0.3px;
    color: #213a2b;
    margin-bottom: 20px;

    @media (max-width: 640px) {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 10px;
    }
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0px;
    color: #396149;
    padding-top: 20px;

    @media (max-width: 640px) {
      line-height: 24px;
      padding-top: 10px;
    }
  }
`;

export const StyledOpportinityHeader = styled.span`
  padding: 8px 16px;
  gap: 16px;
  border-radius: 100px;

  background: linear-gradient(90deg, #e4faee 0%, #f1fcf6 100%);

  font-weight: 400;
  font-size: 20px;
  line-height: 30.94px;
  letter-spacing: 0px;
  color: #279d5a;
  display: inline-block;

  @media (max-width: 640px) {
    font-size: 16px;
  }
`;
