export const OPPORTUNITIES = [
  {
    header: "Выбор ниши",
    title: "Находите перспективные ниши с минимальной конкуренцией",
    description:
      "Сервис каждый день анализирует миллионы товаров на Wildberries и помогает выявить категории, где спрос растёт, а конкуренция остаётся низкой",
  },
  {
    header: "Динамика и тренды",
    title: "Отслеживайте тренды рынка и зарабатывайте на них больше",
    description:
      "Тренды меняются быстро, и те, кто не успевает подстроиться, теряют продажи и прибыль",
  },
  {
    header: "Анализ конкурентов",
    title: "Следите за лидерами рынка и адаптируйте стратегию",
    description:
      "Изучайте поведение топ-продавцов и используйте их тактики для увеличения собственных продаж.",
  },
  {
    header: "Сравнение товаров",
    title: "Сравнивай карточки конкурентов в одном окне",
    description:
      "Если ваши продажи упали — это не случайность. WeCheck помогает найти причину и исправить ситуацию",
  },
  {
    header: "Склады и регионы",
    title:
      "Определяйте склады отгрузки для роста продаж и улучшения локализации",
    description:
      "Нехватка товара на складе или избыточные запасы - это прямые потери для вашего бизнеса. Узнайте, какие регионы приносят больше прибыли и скорректируйте стратегию поставок.",
  },
  {
    header: "Рекламные ставки",
    title: "Оптимизируйте продвижение — больше продаж при меньших затратах",
    description:
      "Интуиция может помочь, но настоящие лидеры рынка принимают решения на основе цифр и фактов.",
  },
];
