import gtag from "ga-gtag";
import React, { useState } from "react";
import ym from "react-yandex-metrika";
import AimIcon from "../../assets/icons/AimIcon";
import AnalyticIcon from "../../assets/icons/AnalyticIcon";
import NoCardIcon from "../../assets/icons/NoCardIcon";
import SettingsIcon from "../../assets/icons/SettingsIcon";
import SwitcherIcon from "../../assets/icons/SwitcherIcon";
import FireIcon from "../../assets/img/fire3.png";
import { ImgPopup, PrimaryButton } from "../../elements";
import { PrimaryButtonLink } from "../../elements/PrimaryButton/PrimaryButtonLink";
import { StyledButtonAdditionText } from "./HeadSectionStyle";

const PlayComponent = () => {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 24.7101V3.28991C1 1.79027 2.58932 0.824092 3.92063 1.5144L24.5758 12.2245C26.015 12.9707 26.015 15.0293 24.5758 15.7755L3.92063 26.4856C2.58932 27.1759 1 26.2097 1 24.7101Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const HeadSectionComponent = () => {
  const [openDemo, setOpenDemo] = useState(false);
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <div className="section relative ">
      <ImgPopup
        video
        closeModal={() => closeModal()}
        open={open}
        title={"Посмотрите видео, как работает WeCheck"}
      />
      <span className="block lg:text-[82px] lg:leading-[72px] text-[32px] leading-[36px] text-[#313d53] font-black">
        Знаете, как продают ваши конкуренты и топы ниш? <br />
        Мы — да.
        {/* и{" "}
            <span className="text-[#0055EB]">ozon</span>*/}
      </span>
      <div className="block text-[#6A7486] lg:text-5xl lg:mt-12 mt-5 font-extrabold lg:leading-[53px] text-[24px] leading-[28px]">
        Сервис аналитики ниш и конкурентов
        <br /> для роста продаж{" "}
        <span className="whitespace-nowrap">
          на <span className="text-[#AA0C91]">Wildberries</span>
        </span>
      </div>
      <div className="mt-4 lg:mt-[50px] block lg:flex lg:space-x-10 space-y-4 lg:space-y-0">
        <div className="space-y-4">
          <div className="flex items-center">
            <div className="w-[30px] h-[30px] border border-black/10 flex items-center justify-center rounded-lg">
              <AnalyticIcon width="24" height="24" className="text-[#7D8694]" />
            </div>
            <div className="w-full lg:w-fit flex justify-between items-center">
              <span className="block ml-5 text-[#313D53]/70 font-medium lg:text-2xl text-base">
                Находите прибыльные ниши и товары
              </span>
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-[30px] h-[30px] border border-black/10 flex items-center justify-center rounded-lg">
              <SwitcherIcon width="24" height="24" className="text-[#7D8694]" />
            </div>
            <div className="w-full lg:w-fit flex justify-between items-center">
              <span className="block ml-5 text-[#313D53]/70 font-medium lg:text-2xl text-base w-[210px] lg:w-auto">
                Следите за конкурентами и топ-селлерами
              </span>
            </div>
          </div>
        </div>
        <div className="space-y-4">
          <div className="flex items-center">
            <div className="w-[30px] h-[30px] border border-black/10 flex items-center justify-center rounded-lg">
              <AimIcon width="24" height="24" className="text-[#7D8694]" />
            </div>
            <div className="w-full lg:w-fit flex justify-between items-center">
              <span className="block ml-5 text-[#313D53]/70 font-medium lg:text-2xl text-base">
                Анализируйте тренды и сезонность
              </span>
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-[30px] h-[30px] border border-black/10 flex items-center justify-center rounded-lg">
              <SettingsIcon width="24" height="24" className="text-[#7D8694]" />
            </div>
            <div className="w-full lg:w-fit flex justify-between  items-center">
              <span className="block ml-5 text-[#313D53]/70 font-medium lg:text-2xl text-base">
                Увеличивайте продажи и прибыль
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="block lg:flex mt-[32px] lg:mt-[60px] mb-[18px] space-y-5 lg:space-y-0">
        <div className="flex flex-col justify-center items-start">
          <PrimaryButtonLink
            href={"https://go.wecheck.ru/signup"}
            subText={
              <span className="flex text-gray-600/60 items-center text-sm">
                <NoCardIcon
                  width="19"
                  height="17"
                  className="relative top-[1px]"
                />
                <span className="block ml-2">Без привязки карты</span>
              </span>
            }
            icon={FireIcon}
            tag={() => {
              gtag("event", "form_send", {
                form: "dostup_head",
              });
              gtag("event", "form_send", {
                form: "24_hour",
              });
              gtag("event", "form_send", {
                form: "all_leads",
              });
            }}
            tag2={() => {
              ym("reachGoal", "form_dostup_head");
              ym("reachGoal", "24_hour");
              ym("reachGoal", "all_leads");
            }}
          >
            Попробовать бесплатно
          </PrimaryButtonLink>
        </div>
        <div className="hidden lg:block mx-[34px] w-px h-[60px] bg-[#22478B]/10" />
        <div className="flex flex-col justify-center">
          <PrimaryButton
            onClick={() => setOpenDemo(true)}
            open={openDemo}
            closeModal={() => setOpenDemo(false)}
            title="Запрос демонстрации"
            type="demonstration"
            icon={FireIcon}
            tag={() => {
              gtag("event", "form_send", { form: "doubt_demo_form" });
              gtag("event", "form_send", { form: "demo_form_all" });
              gtag("event", "form_send", { form: "all_leads" });
            }}
            tag2={() => {
              ym("reachGoal", "doubt_demo_form");
              ym("reachGoal", "demo_form_all");
              ym("reachGoal", "all_leads");
            }}
            subText={
              <span className="flex text-gray-600/60 items-center text-sm">
                <span className="block ml-2">Это бесплатно</span>
              </span>
            }
            useFit
            style={{
              background: "transparent",
              color: "#303F5A",
              border: "2px solid #303F5A",
            }}
          >
            Заказать демонстрацию
          </PrimaryButton>
        </div>
        <div className="hidden lg:block mx-[34px] w-px h-[60px] bg-[#22478B]/10" />
        <div className="flex">
          <div className="w-full lg:w-auto flex items-center h-fit border-[#FFFFFF] z-20 border-2 p-4 lg:p-0 lg:border-none rounded-xl">
            <button
              onClick={handleClick}
              className="w-[60px] h-[60px] flex items-center justify-center border-2 border-[#00745F] rounded-full bg-[#00745F] hover:bg-[#005641]"
            >
              <PlayComponent />
            </button>
            <StyledButtonAdditionText>
              <p>Смотреть </p>
              <p>видео о сервисе</p>
            </StyledButtonAdditionText>
          </div>
        </div>
      </div>
    </div>
  );
};
