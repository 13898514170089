import styled from "styled-components";

export const StyledButton = styled.button`
  position: relative;
  border: none;
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 180%;
  letter-spacing: -0.3px;
  color: #00745f;
  ${(props) =>
    props.sale
      ? "padding: 8px 12px 8px 24px !important;"
      : "padding: 8px 24px !important;"}
  background: transparent;
  border: 1px solid #d7d8d8;
  border-radius: 100px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    color: #333d37;
    ${(props) => props.active && "color: #fff;"}
  }
  @media (max-width: 510px) {
    padding: 8px 16px !important;
    width: 100%;
  }
  ${(props) =>
    props.active &&
    "color: #FFFFFF; background: #00745F;   border: 1px solid #00745F;"}
`;

export const StyledSale = styled.span`
  color: ${({ active }) => (active ? "#fff" : "#eb173e")};
  position: relative;
  inset: -0.7rem -0.3rem auto auto;
  font-family: "Gerbera";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.5px;

  @media (max-width: 870px) {
    position: relative;
    inset: -0.5rem auto auto auto;
    color: #eb173e;
    background: #f4f5f6;
    padding: 0.3rem;
    border-radius: 6rem;
    position: absolute;
    inset: -0.7rem 0% auto auto;
  }
`;
