import gtag from "ga-gtag";
import { useState } from "react";
import ym from "react-yandex-metrika";
import CheckmarkIcon from "../../assets/icons/CheckmarkIcon";
import NewDoubtIcon from "../../assets/icons/NewDoubtIcon";
import Fire from "../../assets/img/fire3.png";
import MobileDoubt from "../../assets/img/mobileDoubt.png";
import { PrimaryButton } from "../../elements";

const NewDoubtSection = () => {
  const [openDemon, setOpenDemon] = useState(
    window.location.hash === "#demonstration_modal"
  );

  const closeModal = () => {
    setOpenDemon(false);
    window.location.hash === "";
  };
  return (
    <div className="relative bottom-gradient">
      <div className="section ">
        <div className="pb-5 lg:hidden">
          <img src={MobileDoubt} className="w-[160px] h-[126px]" />
        </div>
        <div className="block lg:flex items-center">
          <h4> Для всех, кто хочет расти быстрее</h4>
        </div>

        <div className="lg:flex lg:justify-between gap-14">
          <div className="block lg:flex z-20">
            <div>
              <div className=" flex flex-col divide-y divide-[#0000000F] gap-5 lg:pr-24">
                <span className="text-[#213A2B] text-base lg:text-xl leading-[26px] lg:mt-5 lg:mt-4 block">
                  Неважно, только ли вы планируете начать <br /> или уже
                  продаете на
                  <br className="hidden lg:block" />
                  маркетплейсах - <strong>WeCheck</strong> поможет:
                </span>

                <Item label={"Найти выгодные ниши и товары"} />
                <Item label={"Изучить и обойти конкурентов"} />
                <Item label={"Увеличить прибыль и снизить издержки"} />
              </div>
              <div className="mt-5 lg:mt-10">
                <span className="text-[#213A2B] text-xl leading-[26px] lg:mb-5 mb-4 block">
                  Будьте на шаг впереди. <br className="hidden lg:block" />
                  Используйте аналитику для реального роста
                </span>
                <PrimaryButton
                  onClick={() => setOpenDemon(true)}
                  open={openDemon}
                  closeModal={() => closeModal()}
                  title="Запрос демонстрации"
                  type="demonstration"
                  icon={Fire}
                  tag={() => {
                    gtag("event", "form_send", { form: "doubt_demo_form" });
                    gtag("event", "form_send", { form: "demo_form_all" });
                    gtag("event", "form_send", { form: "all_leads" });
                  }}
                  tag2={() => {
                    ym("reachGoal", "doubt_demo_form");
                    ym("reachGoal", "demo_form_all");
                    ym("reachGoal", "all_leads");
                  }}
                  useFit
                >
                  Получить доступ
                </PrimaryButton>
              </div>
            </div>
          </div>
          <div className="lg:block hidden">
            <NewDoubtIcon width="480" height="377" />
          </div>
        </div>
      </div>
      {/* <div
        className="absolute"
        style={{
          top: 802,
          left: -257,
          background: "#279653",
          filter: "blur(250px)",
          width: 748,
          height: 801,
        }}
      />
      <div
        className="absolute"
        style={{
          top: 846,
          left: 548,
          background: "#303F59",
          filter: "blur(250px)",
          width: 454,
          height: 487,
        }}
      />
      <div
        className="absolute"
        style={{
          top: 792,
          left: 1010,
          background: "#279653",
          filter: "blur(250px)",
          width: 687,
          height: 736,
        }}
      /> */}
    </div>
  );
};

export default NewDoubtSection;

const Item = ({ label }) => (
  <div className="flex items-center gap-4 pt-5">
    <CheckmarkIcon /> {label}
  </div>
);
