import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  //   AnalyticSection,
  AnswerSection,
  // DoubtSection,
  HeaderSection,
  RateSection,
} from "../components";
import ConfirmSubscribeModal from "../components/ConfirmSubscribeModal/ConfirmSubscribeModal";
import ConsultationSection from "../components/ConsultationSection/ConsultationSection";
import { NewExtBlock } from "../components/ExtSetupBlock/NewExtBlock";
import NewDoubtSection from "../components/NewDoubtSection/NewDoubtSection";
import OpportunitiesSection from "../components/Opportunities";
import Purposes from "../components/Purposes";
import StartNowSection from "../components/StartNowSection";
import { saveUTMParametersToLocalStorage } from "../utils/utm";
// import {InnerAnalyticSection} from "../components/InnerAnalyticSection/InnerAnalyticSection";

const HomePage = ({ rateRef }) => {
  const location = useLocation();
  const [modalWithScreen, setModalWithScreen] = useState(
    location.hash.includes("#opt-in")
  );

  const closeModalWithScreen = () => {
    history.pushState("", document.title, window.location.pathname);
    setModalWithScreen(null);
  };

  useEffect(() => {
    let hash = location.hash;
    const hashParts = location.hash.split("/");
    if (hashParts.length > 1) {
      hash = hashParts[0];
    }
    hash = hash.replace("#", "");
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        const elementTop = element.getBoundingClientRect().top;
        const offset = 50;

        window.scrollTo({
          top: window.scrollY + elementTop - offset,
          behavior: "smooth",
        });
      }
    }
  }, [location]);

  useEffect(() => {
    saveUTMParametersToLocalStorage();
  }, []);

  return (
    <>
      <HeaderSection />
      {/*<AnalyticSection/>*/}
      {/*<WeCheckBenefitsSection/>*/}
      {/*<BenefitsCardSection/>*/}
      {/*<InnerAnalyticSection />*/}
      {/* <NewToolsSection /> */}
      <OpportunitiesSection />
      <NewExtBlock />
      <ConsultationSection />
      <Purposes />
      <NewDoubtSection />

      <RateSection rateRef={rateRef} />
      <AnswerSection />
      <StartNowSection />

      {modalWithScreen && (
        <ConfirmSubscribeModal closeModal={closeModalWithScreen} />
      )}
    </>
  );
};

export default HomePage;
