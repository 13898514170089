import cn from "classnames";
import { useEffect, useState } from "react";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import LoopIcon from "../../assets/icons/LoopIcon";
import { ImgPopup } from "../../elements";

const Carousel = ({ images = [], title }) => {
  const [activeImage, setActiveImage] = useState(0);
  const [hovered, setIsHovered] = useState(false);
  const [open, setOpen] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const closeModal = () => setOpen(false);
  const handleClickPrev = () => {
    if (activeImage === 0) {
      setActiveImage(images.length - 1);
    } else {
      setActiveImage(activeImage - 1);
    }
  };
  const handleClickNext = () => {
    if (activeImage === images.length - 1) {
      setActiveImage(0);
    } else {
      setActiveImage(activeImage + 1);
    }
  };
  useEffect(() => {
    setIsImageLoading(true);
    const img = new Image();
    img.src = images[activeImage];
    img.onload = () => {
      setIsImageLoading(false);
      setImgSrc(images[activeImage]);
    };
  }, [images[activeImage]]);
  useEffect(() => {
    setActiveImage(0);
  }, [images]);
  return (
    <div
      className="w-full flex flex-col grow relative items-center justify-center lg:rounded-b-lg  "
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      // style={{background: 'radial-gradient(16299.33% 72472.87% at -9.71% 112.08%, #F7E2F3 0%, #F9E6F8 18.42%, #E8E1FD 40.32%, #D6E1FF 61.97%, #E6F0FF 100%)'}}
    >
      <ImgPopup
        img={imgSrc}
        closeModal={() => closeModal()}
        open={open}
        title={title}
      />
      {isImageLoading ? (
        <div className="w-full lg:min-h-[405px] lg:min-w-[600px] lg:max-h-[405px] max-h-[200px] lg:rounded-b-lg" />
      ) : (
        <img
          src={imgSrc}
          className="w-full lg:min-h-[405px] lg:min-w-[600px] lg:max-h-[405px] max-h-[200px] lg:rounded-b-lg"
          alt="Screenshot"
          loading="lazy"
        />
      )}
      <div
        className={cn(
          "absolute flex justify-between items-center h-full w-full lg:rounded-b-lg",
          {
            "bg-[#282828]/20": hovered,
          }
        )}
      >
        <div className="">
          {images.length !== 1 && (
            <button
              className="flex items-center justify-center z-20 rounded-full w-[38px] h-[38px] !bg-white text-[#219653] border border-black/12 ml-3"
              onClick={handleClickPrev}
            >
              <ArrowRightIcon width={14} height={14} className="-rotate-180 " />
            </button>
          )}
        </div>
        {hovered && (
          <div>
            <button
              className="flex items-center justify-center z-20 rounded-full p-4 !bg-white text-[#219653] border border-black/12"
              onClick={() => setOpen(true)}
            >
              <LoopIcon width={24} height={25} />
              <span className="block ml-3 text-base">Увеличить</span>
            </button>
          </div>
        )}

        <div className="">
          {images.length > 1 && (
            <button
              className="flex items-center justify-center z-20 rounded-full w-[38px] h-[38px] !bg-white text-[#219653] border border-black/12 mr-3"
              onClick={handleClickNext}
            >
              <ArrowRightIcon width={14} height={14} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
export default Carousel;
