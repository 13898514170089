import React, { useState } from "react";
import { ReactComponent as ArrowDown } from "../../assets/svg/arrow-down.svg";
import { ReactComponent as ArrowUp } from "../../assets/svg/arrow-up.svg";
import {
  StyledAccordionContent,
  StyledAccordionItem,
  StyledAccordionTitle,
} from "./AccordionStyle";

export const AccordionItem = ({ title, content, index, visible }) => {
  const [isActive, setIsActive] = useState(false);

  let style = {};
  if (!visible) {
    style = {
      margin: 0,
      padding: 0,
      border: "none",
      height: 0,
    };
  }
  return (
    <StyledAccordionItem
      onClick={() => setIsActive(!isActive)}
      style={{
        opacity: visible ? 1 : 0, // Fade in
        transform: !visible ? "translateY(0)" : " translateY(-10px)",
        transitionDelay: visible ? `${index - 5 * 100}ms` : 0,
        ...style,
      }}
    >
      <StyledAccordionTitle isActive={isActive}>
        <p>{title}</p>
        <div>{isActive ? <ArrowUp /> : <ArrowDown />}</div>
      </StyledAccordionTitle>
      {isActive && <StyledAccordionContent>{content}</StyledAccordionContent>}
    </StyledAccordionItem>
  );
};
