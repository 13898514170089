import styled from "styled-components";

export const StyledHead1Text = styled.h1`
  font-family: "Gerbera", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 82px;
  line-height: 82px;
  color: #313d53;
  span:first-child {
    color: #be20a5;
  }
  span:last-child {
    color: #0069ff;
  }
  @media (max-width: 810px) {
    line-height: 147.07%;
    letter-spacing: -0.3px;
    font-size: 64px;
    font-weight: 900 !important;
  }
  @media (max-width: 640px) {
    line-height: 147.07%;
    letter-spacing: -0.3px;
    font-size: 54px;
  }
  @media (max-width: 480px) {
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
  }
`;

export const StyledHead4Text = styled.h4`
  margin-top: 40px;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  letter-spacing: -0.3px;
  color: #696987;
  @media (max-width: 640px) {
    margin-top: 30px;
    font-size: 32px;
    line-height: 37px;
  }
  @media (max-width: 480px) {
    margin-top: 12px;
    font-size: 24px;
    line-height: 37px;
  }
`;

export const StyledButtonAdditionText = styled.div`
  margin-left: 24px;
  //padding-left: 24px;
  //border-left: 1px solid rgba(255, 255, 255, 0.3);
  p {
    font-family: "Sofia Pro", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #535a69;
  }
  //p:first-child {
  //  margin-bottom: 10px;
  //}
  @media (max-width: 740px) {
    margin-left: 16px;
    padding-left: 0;
    border: none;
  }
`;

export const StyledButtonGroupVideo = styled.div`
  max-width: 670px;
  display: flex;
  align-items: center;
  margin-top: 50px;
  padding: 14px;
  padding-top: 0px;
  padding-right: 48px;
  box-sizing: border-box;
  border-radius: 100px;
  // border: 2px solid #ffffff;
  @media (max-width: 740px) {
    margin-left: 0 !important;
    & div {
      margin-left: 24px;
      margin-top: 0;
      font-size: 16px;
      line-height: 22px;
      color: #535a69;
    }
  }
  @media (max-width: 480px) {
    padding: 16px;
    border: 2px solid #ffffff;
    border-radius: 12px;
    margin-top: 24px;
  }
`;

export const StyledButtonGroup = styled.div`
  max-width: 670px;
  display: flex;
  align-items: center;
  margin-top: 50px;
  padding-top: 0px;
  padding-right: 34px;
  box-sizing: border-box;
  ${(props) => {
    return `
    padding-left: ${props.noLeftPadding ? 0 : 24}px !important;
    padding-bottom: ${props.noLeftPadding ? 5 : 24}px !important;
    padding-top: ${props.noLeftPadding ? 5 : 0}px !important;
    margin-bottom: ${props.noLeftPadding ? 19 : 0}px !important;
    border-right: ${
      props.noLeftPadding ? "1px solid rgba(34, 71, 139, 0.12)" : "none"
    } !important;
    border-radius:${props.noLeftPadding ? 0 : 100}px !important;`;
  }};
  @media (max-width: 740px) {
    text-align: center;
    border: none;
    flex-direction: column;
    padding: 0;
    border-radius: 0;
  }

  @media (max-width: 480px) {
    // padding-top: 24px;
    max-width: 100%;
    margin-top: 24px;
    // border-top: 1px solid rgba(0, 0, 0, 0.06);
  }
`;

export const StyledPlayButton = styled.button`
  display: flex;
  align-items: center;
  width: 56px;
  height: 56px;
  border: 2px solid #00745f;
  border-radius: 50%;
  background-color: #00745f;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: #005641;
  }
`;

export const StyledSuperPrice = styled.a`
  position: absolute;
  right: 150px;
  top: 260px;
  text-decoration: none;
  @media (max-width: 740px) {
    display: none;
  }
`;

export const StyledButtonsSplitter = styled.div`
  display: flex;
  @media (max-width: 740px) {
    display: block;
  }
`;

export const StyledSuperPriceMobile = styled.a`
  display: none;
  @media (max-width: 740px) {
    display: block;
  }
`;
