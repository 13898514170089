import { useLinks } from "../hooks";
import { EMAIL } from "../utils";

const Licence = () => {
  const { agreementLink, licenceLink } = useLinks();
  return (
    <div>
      <div className="m-10">
        <h1 className="text-4xl mb-5 font-bold">
          Политика Конфиденциальности (Обработки Персональных Данных) сервиса
          wecheck
        </h1>
        Дата размещения на Сайте «12» сентября 2024 года
        <br />
        Настоящая Политика конфиденциальности (далее – Политика) определяет
        общие принципы и порядок обработки персональных данных и меры по
        обеспечению их безопасности у ООО&nbsp;«ВИЧЕК», ИНН 9718180317 КПП
        770401001 (далее - Оператор)&nbsp;
        <br />‍<br />‍
        <strong>
          ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ&nbsp;
          <br />‍
        </strong>
        Стороны используют следующие термины в указанном ниже значении:&nbsp;
        <ul className="list-decimal list-inside">
          <li>
            Сайт – сайт, расположенный в сети Интернет по адресу{" "}
            <a href="https://www.wecheck.ru">https://www.wecheck.ru/</a> а также
            все его страницы и все его поддомены.
          </li>
          <li>
            Субъект персональных данных – физическое, юридическое или любое
            другое лицо к которому относятся Персональные данные.
          </li>
          <li>
            Законодательство– действующее законодательство Российской Федерации.
          </li>
          <li>
            Пользователь– лицо, вступившее с Оператором в договорные отношения.
          </li>
          <li>
            Персональные данные – любая информация, относящаяся к прямо или
            косвенно определённому или определяемому физическому лицу или иному
            лицу (Пользователю).
          </li>
          <li>
            Автоматизированная обработка персональных данных – обработка
            Персональных данных с помощью средств вычислительной техники.
          </li>
          <li>
            Блокирование персональных данных – временное прекращение обработки
            Персональных данных (за исключением случаев, когда обработка
            необходима для уточнения Персональных данных).
          </li>
          <li>
            Доступ к персональным данным – ознакомление определённых лиц с
            Персональными данными, обрабатываемыми Оператором, при условии
            сохранения конфиденциальности этих сведений.
          </li>
          <li>
            Информационная система персональных данных – совокупность
            содержащихся в базах данных Персональных данных и обеспечивающих их
            обработку информационных технологий и технических средств.
          </li>
          <li>
            Конфиденциальность персональных данных – обязанность лиц, получивших
            доступ к Персональным данным, не раскрывать третьим лицам и не
            распространять Персональные данные без согласия Пользователя
            Персональных данных, если иное не предусмотрено федеральным
            законодательством.
          </li>
          <li>
            Не декларированные (не документированные) возможности программного
            обеспечения – функциональные возможности программного обеспечения,
            не описанные или не соответствующие описанным в документации, при
            использовании которых возможно нарушение характеристик безопасности
            защищаемой информации.
          </li>
          <li>
            Обработка персональных данных – любое действие (операция) или
            совокупность действий(операций), совершаемых с использованием
            средств автоматизации или без использования таких средств, с
            Персональными данными, включая сбор, запись, систематизацию,
            накопление, хранение, уточнение (обновление, изменение),извлечение,
            использование, передачу (распространение, предоставление,
            доступ),обезличивание, блокирование, удаление, уничтожение
            Персональных данных.
          </li>
        </ul>
        <br />‍
        <strong>
          1. ОБЩИЕ ПОЛОЖЕНИЯ
          <br />‍
        </strong>
        1.1. Политика в отношении обработки персональных данных разработана в
        соответствии с положениями Федерального закона от 27.07.2006 №152-ФЗ «О
        персональных данных» (с изменениями и дополнениями), другими
        законодательными и нормативными правовыми актами и определяет порядок
        работы с Персональными данными Пользователей и(или) передаваемых
        Пользователями и требования к обеспечению их безопасности.
        <br />
        1.2. Мероприятия по обеспечению безопасности персональных данных
        являются составной частью деятельности Оператора.
        <br />
        1.3. Оператор обрабатывает Персональные данные на основании
        Лицензионного соглашения Сервиса, размещенного по адресу:{" "}
        <a href={agreementLink}>https://www.wecheck.ru/agreement</a>
        .&nbsp;
        <br />
        <br />‍
        <strong>
          2. ПРИНЦИПЫ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
          <br />‍
        </strong>
        2.1. Обработка Персональных данных Оператором осуществляется в
        соответствии со следующими принципами:
        <br />
        2.1.1. Законность и справедливая основа обработки Персональных данных.
        Оператор принимает все необходимые меры по выполнению требований
        Законодательства, не обрабатывает Персональные данные в случаях, когда
        это не допускается Законодательством, не использует Персональные данные
        во вред Пользователю.
        <br />
        2.1.2. Обработка только тех Персональных данных, которые отвечают за
        ранее объявленным целям их обработки. Соответствие содержания и объёма
        обрабатываемых Персональных данных заявленным целям обработки.
        Недопущение обработки Персональных данных, не совместимых с целями сбора
        Персональных данных, а также избыточных по отношению к заявленным целям
        их обработки. Оператор обрабатывает Персональные данные исключительно в
        целях исполнения договорных обязательств перед Пользователем.
        <br />
        2.1.3. Обеспечение точности, достаточности и актуальности Персональных
        данных по отношению к целям обработки Персональных данных. Оператор
        принимает все разумные меры по поддержке актуальности обрабатываемых
        Персональных данных, включая, но не ограничиваясь реализацией права
        каждого Субъекта получать для ознакомления свои Персональные данные и
        требовать от Оператора их уточнения, блокирования или уничтожения в
        случае, если Персональные данные являются неполными, устаревшими,
        неточными, незаконно полученными или не являются необходимыми для
        заявленных выше целей обработки.
        <br />
        2.1.4. Хранение Персональных данных в форме, позволяющей определить
        Пользователя Персональных данных, не дольше, чем этого требуют цели
        обработки Персональных данных, если срок хранения Персональных данных не
        установлен федеральным законодательством, договором, стороной которого
        или выгодоприобретателем, по которому является Пользователь Персональных
        данных.
        <br />
        2.1.5. Недопустимость объединения созданных для несовместимых между
        собой целей баз данных Информационных систем Персональных данных.&nbsp;
        <br />
        <br />‍
        <strong>
          3. УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
          <br />‍
        </strong>
        3.1. Обработка Персональных данных Оператором допускается в следующих
        случаях:
        <br />
        3.1.1. При наличии согласия Пользователя на обработку его Персональных
        данных.
        <br />
        3.1.2. При передаче Персональных данных Субъекта персональных данных
        Пользователем. Пользователь гарантирует, что предварительно получил от
        Субъекта персональных данных согласие на передачу данных Оператору.
        <br />
        3.1.3. Персональные данные подлежат опубликованию или обязательному
        раскрытию в соответствии с Законодательством.
        <br />
        3.2. Оператор не раскрывает третьим лицам и не распространяет
        Персональные данные без согласия Пользователя, если иное не
        предусмотрено Законодательством.
        <br />
        3.3. Оператор не принимает решений, порождающих юридические последствия
        в отношении Пользователя или иным образом затрагивающих права и законные
        интересы Пользователей, на основании исключительно автоматизированной
        обработки Персональных данных. &nbsp;
        <br />
        <br />‍
        <strong>
          4. СБОР И ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ И ИНЫХ ДАННЫХ
          <br />‍
        </strong>
        4.1. Сбор Персональных данных может осуществляться как посредством
        Сайта, так и любым иным способом
        <br />
        4.2. Персональные данные могут использоваться в следующих целях:
        <br />
        4.2.1. Продажа услуг Пользователю.
        <br />
        4.2.2. Идентификация Пользователя.
        <br />
        4.2.3. Взаимодействие с Пользователем.
        <br />
        4.2.4. Направление Пользователю рекламных материалов, информации,
        уведомлений и запросов.
        <br />
        4.2.5. Проведение статистических и иных исследований.
        <br />
        4.2.6. Передача партнерам для проведения рекламных кампаний.
        <br />
        4.3. Оператор обязуется использовать Персональные данные в соответствии
        с Федеральным законом «О персональных данных» №152-ФЗ от 27 июля 2006 г.
        и внутренними документами Оператора.
        <br />
        4.4. В отношении Персональных данных и иных Данных Пользователя
        сохраняется их конфиденциальность, кроме случаев, когда указанные данные
        являются общедоступными.
        <br />
        4.5. Оператор имеет право сохранять архивную копию Персональных данных и
        иных Данных, в том числе после удаления аккаунта Пользователя.
        <br />
        4.6. Оператор имеет право передавать Персональные данные Пользователя
        без согласия Пользователя следующим лицам:
        <br />
        4.6.1. Государственным органам, в том числе органам дознания и
        следствия, и органам местного самоуправления по их мотивированному
        запросу.
        <br />
        4.6.2. Партнёрам Оператора c целью выполнения договорных обязательств
        перед Пользователем.
        <br />
        4.6.3. Партнёрам Оператора c целью осуществления рекламных
        имаркетинговых кампаний.
        <ul className="list-disc list-inside">
          <li>
            ООО&quot;Биспоук РУС&quot; (ИНН 7703700520, ОГРН 1097746319692,
            адрес: 123112, г.Москва, наб. Пресненская, д. 12, помещ. 10/45, оф.
            349 в целях маркетинговойкампании)
          </li>
        </ul>
        4.6.4. В иных случаях, прямо предусмотренных действующим
        законодательством РФ.
        <br />
        4.7. Оператор имеет право передавать Персональные данные третьим лицам,
        не указанным в п. 4.6 настоящей Политики, в следующих случаях:
        <br />
        4.7.1. Пользователь выразил свое согласие на такие действия.
        <br />
        4.7.2. Передача необходима в рамках использования Пользователем Сайта
        или в рамках исполнения договорных обязательств перед Пользователем.
        <br />
        4.7.3. Передача происходит в рамках продажи или иной передачи бизнеса
        (полностью или в части), при этом к приобретателю переходят все
        обязательства по соблюдению условий настоящей Политики.
        <br />
        4.8. Оператор осуществляет автоматизированную обработку Персональных
        данных и иных Данных.
        <br />
        4.9. Доступ к Информационным системам, содержащим Персональные данные,
        обеспечивается системой паролей. Пароли устанавливаются уполномоченными
        сотрудниками Оператора и индивидуально сообщаются работникам Оператора,
        имеющим доступ к Персональным данным/Данным.
        <br />
        4.10. С целью обеспечения работоспособности Сайта для ведения статистики
        и анализа работы Сайта Оператор обрабатывает указанные Персональные
        данные с использованием метрического сервиса Яндекс.Метрика.
        <br />
        4.10.1. Сервис Яндекс.Метрика:
        <ul className="list-disc list-inside">
          <li>
            Доступен по адресу
            <a href="http://api.yandex.com/metrika">
              http://api.yandex.com/metrika
            </a>
          </li>
          <li>
            Принадлежит ООО «Яндекс», зарегистрированного по адресу 119021,
            Москва, ул. Льва Толстого,д.16 (далее – Яндекс).
          </li>
          <li>
            Информация, хранящаяся в таких файлах cookie передается и
            сохраняется на серверах Яндекс.Пользователь дает согласие Оператору
            на обработку и передачу данных Яндексу.
          </li>
          <li>
            Пользователь вправе заблокировать сервис Яндекс.Метрики. Для этого
            можно скачать и установить надстройку по ссылке.
          </li>
          <li>
            При блокировке Яндекс.Метрики некоторые функции Сервиса могут стать
            недоступны.
          </li>
        </ul>
        <br />‍
        <strong>
          5. ИЗМЕНЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ
          <br />‍
        </strong>
        5.1. Пользователь может в любой момент изменить (обновить, дополнить)
        Персональные данные посредством Личного кабинета либо путём направления
        письменного заявления Оператору.
        <br />
        5.2. Пользователь в любой момент имеет право удалить Персональные
        данные/Данные.&nbsp;
        <br />
        <br />‍
        <strong>
          6. КОНФИДЕНЦИАЛЬНОСТЬ ПЕРСОНАЛЬНЫХ ДАННЫХ
          <br />‍
        </strong>
        6.1. Оператор обеспечивает конфиденциальность обрабатываемых им
        Персональных данных/Данных в порядке, предусмотренном Законодательством.
        Обеспечение конфиденциальности не требуется в отношении:
        <br />
        6.1.1. Персональных данных после их обезличивания.
        <br />
        6.1.2. Персональных данных, доступ неограниченного круга лиц к которым
        предоставлен Пользователем либо по его просьбе (далее – Персональные
        данные, сделанные общедоступными Пользователем).
        <br />
        6.1.3. Персональных данных, подлежащих опубликованию или обязательному
        раскрытию в соответствии с Законодательством.&nbsp;
        <br />
        <br />‍
        <strong>
          7. СОГЛАСИЕ ПОЛЬЗОВАТЕЛЯ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ
          <br />‍
        </strong>
        7.1. Пользователь даетсогласие на обработку перечисленных, но не
        ограничиваясь следующими данными:
        <ul className="list-none list-inside">
          <li>a) ФИО;</li>
          <li> b) Номер телефона;</li>
          <li>c) Адрес электронной почты;</li>
          <li> d) Адрес;</li>
          <li>e) аккаунты в мессенджерах;</li>
          <li>f) аккаунты в социальных сетях.</li>
        </ul>
        7.2. Пользователь принимает решение о предоставлении своих Персональных
        данных Оператору и даёт согласие на их обработку свободно, своей волей и
        в своём интересе. Согласие на обработку Персональных данных должно быть
        конкретным, информированным и сознательным и предоставляется
        Пользователем в момент его Регистрации на Сайте Оператора, а также в
        любой позволяющей подтвердить факт его получения форме, если иное не
        установлено Законодательством.
        <br />
        7.3. Персональные данные лиц, вступивших в договорные обязательства с
        Оператором, содержащиеся в единых государственных реестрах юридических
        лиц и индивидуальных предпринимателей, являются открытыми и
        общедоступными, за исключением сведений о номере, дате выдачи и органе,
        выдавшем документ, удостоверяющий личность физического лица. Охраны их
        конфиденциальности и согласия Пользователей на обработку не требуется.
        <br />
        7.4. В случае поступления запросов из организаций, не обладающих
        соответствующими полномочиями, Оператор обязан получить предварительное
        согласие Пользователя на предоставление его Персональных данных и
        предупредить лиц, получающих Персональные данные, о том, что эти данные
        могут быть использованы лишь в целях, для которых они сообщены, а также
        требовать от этих лиц подтверждения того, что это правило будет
        (было)соблюдено.&nbsp;
        <br />
        <br />‍
        <strong>
          8. ПРАВА СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ
          <br />‍
        </strong>
        8.1. Пользователь имеет право на получение информации, касающейся
        обработки его Персональных данных/Данных. Пользователь вправе требовать
        от Оператора уточнения его Персональных данных, их блокирования или
        уничтожения в случае, если Персональные данные являются неполными,
        устаревшими, неточными, незаконно полученными или не являются
        необходимыми для заявленной цели обработки, а также принимать
        предусмотренные законом меры по защите своих прав. Оператор уничтожает
        Персональные данные путем стирания.
        <br />
        8.2. Если Пользователь считает, что Оператор осуществляет обработку его
        Персональных данных с нарушением требований Законодательства или иным
        образом нарушает его права и свободы, Пользователь вправе обжаловать
        действия или бездействие Оператора в уполномоченном органе по защите
        прав субъектов Персональных данных или в судебном порядке.
        <br />
        8.3. Пользователь имеет право на защиту своих прав и законных интересов,
        в том числе на возмещение убытков и (или) компенсацию морального вреда в
        судебном порядке.&nbsp;
        <br />
        <br />‍
        <strong>
          9. ПЕРСОНАЛЬНЫЕ ДАННЫЕ ТРЕТЬИХ ЛИЦ, ИСПОЛЬЗУЕМЫЕПОЛЬЗОВАТЕЛЯМИ
          <br />‍
        </strong>
        9.1. Используя Сайт, Пользователь имеет право заносить данные третьих
        лиц для последующего использования (Получатели Услуги).
        <br />
        9.2. Пользователь обязуется получить предварительное согласие субъекта
        Персональных данных на их использование посредством Сайта.
        <br />
        9.3. Оператор обязуется предпринять необходимые меры для обеспечения
        сохранности Персональных данных третьих лиц, занесённых
        Пользователем.&nbsp;
        <br />
        <br />‍
        <strong>
          10. ИНЫЕ ПОЛОЖЕНИЯ
          <br />‍
        </strong>
        10.1. К настоящей Политике и отношениям между Пользователем и
        Оператором, возникающим в связи с применением Политики, подлежит к
        применению право Российской Федерации.
        <br />
        10.2. Пользователь вправе направить Оператору свои обращения, в том
        числе отозвать согласие на Обработку, в форме электронного документа по
        адресу электронной почты: <a href={"mailto:" + EMAIL}>{EMAIL}</a>.{" "}
        <br />
        10.3. Все возможные споры подлежат разрешению в соответствии с
        Законодательством по месту регистрации Оператора. Перед обращением в суд
        Пользователь должен соблюсти обязательный досудебный порядок и направить
        Оператору соответствующую претензию в письменном виде. Срок ответа на
        претензию составляет 10 (десять) рабочих дней.
        <br />
        10.4. Если по тем или иным причинам одно или несколько положений
        Политики будут признаны недействительными или не имеющими юридической
        силы, это не оказывает влияния на действительность или применимость
        остальных положений Политики конфиденциальности.
        <br />
        10.5. Если Оператор установит факт неправомерной или случайной передачи
        Персональных данных, повлекшей нарушение прав Субъекта, Оператор обязан:
        <br />
        10.5.1. в течение 24(двадцати четырех) часов с момента инцидента
        уведомить Роскомнадзор о произошедшем инциденте и о принятых мерах по
        устранению его последствий, а также предоставить сведения о лице,
        уполномоченном Оператором на взаимодействие с Роскомнадзором по
        вопросам, связанным с выявленным инцидентом;
        <br />
        10.5.2. в течение 72(семидесяти двух) часов с момента инцидента
        уведомить Роскомнадзор о результатах внутреннего расследования
        выявленного инцидента, а также предоставить сведения о лицах, действия
        которых стали причиной выявленного инцидента (при наличии). <br />
        10.6. Оператор имеет право в любой момент изменять настоящую Политику
        (полностью или в части) в одностороннем порядке без предварительного
        согласования с Пользователем. Все изменения вступают в силу на следующий
        день после размещения на Сайте.
        <br />
        10.7. Пользователь обязуется самостоятельно следить за изменениями
        Политики конфиденциальности путем ознакомления с актуальной
        редакцией.&nbsp;
        <br />
        <br />‍
        <strong>
          11. КОНТАКТНАЯ ИНФОРМАЦИЯ ОПЕРАТОРА
          <br />‍
        </strong>
        ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ &quot;ВИЧЕК&quot;
        <br />
        ИНН 9718180317
        <br />
        КПП 770401001
        <br />
        ОГРН 1217700491821
        <br />
        Расчётный счёт 40702810210001668842
        <br />
        БИК 044525974
        <br />
        Название банка АО «ТБанк»
        <br />
        Корр. счёт 30101810145250000974
        <br />
        Юридический адрес 119021,г. Москва, вн.тер.г. муниципальный округ
        Хамовники, б-р Зубовский, д. 21, стр.1, помещ. 1/3
        <br />
        Электронная почта: <a href={"mailto:" + EMAIL}>{EMAIL}</a>
        <br />
        Политика конфиденциальности размещена в сети Интернет по адресу:{" "}
        <a href={licenceLink}>https://www.wecheck.ru/licence</a>
      </div>
    </div>
  );
};

export default Licence;
