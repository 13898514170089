const CheckmarkIcon = (props) => {
  return (
    <svg
      width="32"
      height="32"
      {...props}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#00745F" />
      <path
        d="M10.1816 16.6842L13.4128 20.4543L21.5925 10.9092"
        stroke="#F9F9FA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckmarkIcon;
