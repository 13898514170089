import Popup from "reactjs-popup";
import styled, { css } from "styled-components";

const brandGreyColor = "#6e6e6e";
const brandWhiteColor = "#fff";

const inputFontsCss = css`
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 47px;
`;

export const StyledPopup = styled(Popup)`
  @keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }

    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }

    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
  }

  &-content {
    display: flex;
    background-color: ;
    max-width: 80%;
    animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

    @media (max-width: 640px) {
      max-width: 100%;
    }
  }

  &-overlay {
    background: #0000008c;
  }
`;
export const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledModalHeader = styled.div`
  background: ${brandWhiteColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  border-bottom: 1px solid #e8e8e8;
  position: relative;

  p {
    text-align: left;
    max-width: 20rem;
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #333333;
  }

  img {
    position: absolute;
    inset: 2rem 2rem auto auto;
    cursor: pointer;
  }
`;

export const StyledModalContent = styled.div`
  width: 31rem;
  background: #f7f7f7;
  padding: 32px;
  box-sizing: border-box;
  display: grid;
  gap: 1rem;
`;

export const StyledPopupButton = styled.button`
  color: ${brandWhiteColor};
  background: #219653;
  border-radius: 100px;
  padding: 1rem;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
  border: none;
  width: 100%;
  transition: all ease 0.3s;

  &:hover {
    background-color: #1c7e46;
  }

  &:active {
    background-color: #186e3d;
  }

  &:disabled {
    background: #ccc;
  }
`;

const StyledInput = styled.input`
  width: 100%;
`;

export const StyledPasswordInput = styled(StyledInput).attrs({
  type: "password",
  required: true,
})`
  ${inputFontsCss};
  background: ${brandWhiteColor};
  color: ${brandGreyColor};
  -webkit-appearance: none;
  padding: 12px 24px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  border-radius: 8px;
  

  &::placeholder {
    ${inputFontsCss};
    color: ${brandGreyColor};
  }
}
`;
export const StyledError = styled.p`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: all ease 0.3s;
  font-size: 18px;
  color: red;
  width: 100%;
  text-align: center;
  padding: 0.5rem 0;
`;

export const StyledModalBody = styled.div`
  display: grid;
  gap: 1rem;
`;

export const StyledModalFooter = styled.footer``;
