import Popup from "reactjs-popup";
import styled from "styled-components";

export const StyledPopupTooltip = styled(Popup)`
  @keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
  }
  &-content {
    display: flex;
    background-color: #fff;
    max-width: 80%;
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    @media (max-width: 640px) {
      max-width: 100%;
    }
  }
  &-overlay {
    background: #0000008c;
  }
`;

export const StyledWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  margin-right: 20px;
  padding: 8px 12px;
  height: fit-content;
  display: flex;
  @media (max-width: 480px) {
    border: none;
    margin-right: 0px;
  }
`;

export const StyledPhoneIcon = styled.div`
  height: 20px;
  align-self: center;
`;

export const StyledPhone = styled.div`
  display: flex;
  padding-right: 8px;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  margin-right: 8px;
  color: #213a2b;
  @media (max-width: 480px) {
    display: none;
  }
`;

export const StyledPhoneNumber = styled.span`
  padding-left: 8px;
  font-weight: 500;
  font-size: 18px;
`;

export const StyledChats = styled.div`
  display: flex;
  width: 90px;
  @media (max-width: 640px) {
    width: auto;
  }
`;

export const StyledOtherChats = styled.div`
  display: flex;
  @media (max-width: 480px) {
    display: none;
  }
`;

export const StyledLink = styled.a`
  height: 30px;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 480px) {
    padding-right: 6px;
    margin-left: 16px;
    border-right: 1px solid rgba(0, 0, 0, 0.06);
  }
`;

export const StyledPopupContent = styled.div`
  font-family: "Gerbera";
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  background: white;
  opacity: 1;
  border-radius: 8px;
  max-width: 300px;
`;

export const StyledChatsMenu = styled.div`
  display: none;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding: 0 12px;
  @media (max-width: 640px) {
    display: block;
  }
`;

export const StyledMenuChat = styled.a`
  padding: 12px 0;
  display: block;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledMenuChatText = styled.span`
  display: flex;
  alight-items: center;
  color: #213a2b;
  font-weight: 500;
  font-size: 16px;
  & > span {
    position: relative;
    top: 3px;
  }
`;
