import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ym from "react-yandex-metrika";
import styled from "styled-components";
import { EmailPopup, RegisterPopup } from "..";
import CloseModal from "../../assets/img/close-modal.png";
import { Captcha } from "../../components/Captcha";
import { useLinks } from "../../hooks";
import { apiUrl } from "../../utils";
import { handlePhoneChange } from "../../utils/common";
import { addUTMParametersToUrlForSubmit } from "../../utils/utm";
import LoaderSpinner from "../LoaderSpinner";
import {
  StyledError,
  StyledModalContent,
  StyledModalHeader,
  StyledPopup,
} from "./RequestPopupStyle";

// const OverlayLoader = styled.div`
//   z-index: ${({ isVisible }) => isVisible ? '10000' : '-10000'};
//   opacity: ${({ isVisible }) => isVisible ? 0.8 : 0};
//   transition: all ease 0.15s;
//   display: flex;
//   flex-flow: row nowrap;
//   align-items: center;
//   justify-content: center;
//   height: 100%;
//   width: 100%;
//   position: absolute;
//   background: #fff;
// `

const ButtonSubmit = styled.button.attrs({
  type: "submit",
})`
  padding: ${({ isPending }) => (isPending ? "0.4rem" : "1.1rem")};
  width: 100%;
  background: #00745f;
  border-radius: 100px;
  margin-top: 40px;
  border: none;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: #00745f;
  }

  &:active {
    background: #166839;
  }

  &:disabled {
    pointer-events: none;
    cursor: auto;
    background: #ccc;
  }
`;

const ButtonInnerText = styled.p`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  font-style: normal;
  font-family: "Sofia Pro" !important;
  font-weight: 500 !important;
  font-size: 1.2rem !important;
  line-height: 1.4rem !important;
  color: #ffffff !important;
`;

const PHONE_NUMBER_BEGINS_WITH = "+7";

// eslint-disable-next-line no-unused-vars
export const RequestPopup = ({ title, closeModal, open, type, tag, tag2 }) => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState(PHONE_NUMBER_BEGINS_WITH);
  const [status, setStatus] = useState();
  const [isPending, setIsPending] = useState(false);
  const [isRegistered, setIsRegistered] = useState(true);
  const [isNewUser, setIsNewUser] = useState();
  const [error, setError] = useState({ privacy: false, request: false });
  const [phoneError, setPhoneError] = useState(false);
  // Captcha
  const [captchaVisible, setCaptchaVisible] = useState(false);
  const [resetCaptcha, setResetCaptcha] = useState(0);

  const { licenceLink } = useLinks();

  const reInit = () => {
    setOpenSuccess(false);
    setName("");
    setEmail("");
    setNumber("+7");
    setStatus();
    setIsPending(false);
    setIsRegistered(true);
    setIsNewUser(false);
    setError({ privacy: false, request: false });
    setPhoneError(false);
  };

  const computedRequestStatus = useMemo(() => {
    if (!status && status !== undefined)
      return <StyledError>Не удалось отправить данные</StyledError>;
    if (error.request)
      return (
        <StyledError>
          Ошибка при запросе к серверу. Пожалуйста, повторите запрос
        </StyledError>
      );

    return;
  }, [status, isPending, error.request]);

  const handleSubmit = (captcha) => {
    if (phoneError) {
      return;
    }
    if (tag) {
      tag();
    }

    if (tag2) {
      tag2();
    }

    setIsPending(true);
    setStatus(1);

    addUTMParametersToUrlForSubmit(
      `${apiUrl}/saveform?name=${name}&email=${email}&phone_number=${number}&captcha=${captcha}&type=${
        type ? type : "demo_access"
      }`,
      "&"
    ).then((postURL) => {
      axios
        .post(postURL)
        .then(({ data }) => {
          ym("reachGoal", "landing_forms_050325");
          setError((previous) => ({ ...previous, request: false }));
          setStatus(data.status);
          data.status && setOpenSuccess(true);
        })
        .catch(() => {
          setError((previous) => ({ ...previous, request: true }));
        })
        .finally(() => {
          setCaptchaVisible(false);
          setIsPending(false);
          setResetCaptcha((prev) => prev + 1);
        });
    });
  };

  const setCaptchaValue = (captcha) => {
    handleSubmit(captcha);
  };

  const onChallengeVisible = () => {
    setIsPending(true);
  };

  const handleChallengeHidden = useCallback(() => {
    setCaptchaVisible(false);
  }, []);

  const handleSubmitWithCaptcha = (e) => {
    e.preventDefault();
    // validate
    const isPrivacyAccept = e.target[3].checked;
    const privacyName = e.target[3].name;
    const isPhoneEmpty =
      !number ||
      number === PHONE_NUMBER_BEGINS_WITH ||
      phoneError ||
      number.length !== 18;

    if (isPhoneEmpty) {
      setPhoneError(true);
    } else if (isPrivacyAccept) {
      setError((prev) => {
        return {
          ...prev,
          [privacyName]: false,
        };
      });
      setPhoneError(false);
      setCaptchaVisible(true);
    } else {
      setError((prev) => {
        return {
          ...prev,
          [privacyName]: true,
        };
      });
    }
  };

  const onRegisterClose = () => {
    setIsRegistered(true);
  };

  const onRegisterSuccess = () => {
    setIsRegistered(true);
    setIsNewUser(true);
  };

  const onConfirmRegisterSubmit = () => {
    setIsNewUser(false);
    closeModal();
    reInit();
  };

  const handleAccept = (e) => {
    setError((prev) => {
      return {
        ...prev,
        privacy: !e.target.checked,
      };
    });
  };

  useEffect(() => {
    return () => {
      reInit();
    };
  }, []);

  if (open && isNewUser) {
    return (
      <EmailPopup
        open
        title="Ура! А теперь последний шаг"
        bodyText="Для завершения регистрации, пожалуйста, перейдите по ссылке, которую мы отправили Вам на e-mail"
        submitText="Хорошо"
        closeModal={onConfirmRegisterSubmit}
      />
    );
  }

  if (open && !isRegistered) {
    return (
      <RegisterPopup
        isOpened
        onCloseModal={onRegisterClose}
        onSuccess={onRegisterSuccess}
        username={name}
        email={email}
      />
    );
  }

  if (
    open &&
    !status !== undefined &&
    status !== 1 &&
    openSuccess &&
    !error.request
  ) {
    return (
      <EmailPopup
        title={"Запрос отправлен"}
        closeModal={() => {
          setOpenSuccess(false);
          closeModal();
        }}
        open={openSuccess}
      />
    );
  }

  return (
    <StyledPopup
      modal
      lockScroll
      closeOnDocumentClick
      openSuccess={openSuccess}
      open={open}
      onClose={() => {
        setOpenSuccess(false);
        closeModal();
      }}
    >
      <LoaderSpinner
        isWithOverlay
        isVisible={isPending}
        style={{ height: "8rem", width: "8rem" }}
        spinnerColor="#00745F"
        spinnerBorderWidth="1rem"
      />
      <StyledModalHeader>
        <p>{title}</p>
        <img
          onClick={() => {
            setOpenSuccess(false);
            closeModal();
          }}
          src={CloseModal}
          alt="no img"
        ></img>
      </StyledModalHeader>
      <StyledModalContent>
        <form onSubmit={(e) => handleSubmitWithCaptcha(e)}>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            placeholder="Представьтесь*"
            type="text"
          />
          <div style={{ width: "100%" }}>
            <input
              value={number}
              onChange={(e) =>
                handlePhoneChange(e, (e) => {
                  setPhoneError(false);
                  setNumber(e.target.value);
                })
              }
              required
              placeholder="Телефон*"
              type="phone"
              onBlur={() => {
                if (number.length !== 18) {
                  setPhoneError(true);
                }
              }}
              style={
                phoneError ? { border: "2px solid red", marginBottom: 0 } : {}
              }
            />
            {phoneError && (
              <p style={{ color: "red", marginBottom: 16 }}>
                Телефон заполнен не до конца.
              </p>
            )}
          </div>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Email*"
            type="email"
          />
          <label>
            <input name="privacy" type="checkbox" onClick={handleAccept} />
            Принимаю
            <a href={licenceLink} style={{ color: "#00745F" }}>
              политику конфиденциальности
            </a>
          </label>
          {error.privacy && <p>Пожалуйста, подтвердите согласие</p>}
          <ButtonSubmit disabled={isPending}>
            <ButtonInnerText isVisible={!isPending}>Отправить</ButtonInnerText>
          </ButtonSubmit>
          <Captcha
            visible={captchaVisible}
            onChange={setCaptchaValue}
            onChallengeHidden={handleChallengeHidden}
            captchaKey={resetCaptcha}
            onChallengeVisible={onChallengeVisible}
          />
          {computedRequestStatus}
        </form>
      </StyledModalContent>
    </StyledPopup>
  );
};
