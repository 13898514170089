import styled from "styled-components";

export const StyledSection = styled.section`
  background: #00745f;
  // border-radius: 0px 0px 80px 80px;
  padding: 80px 0 112px;
  // margin-top: -80px;
  z-index: 2;
  @media (max-width: 870px) {
    border-radius: 0;
    padding: 10px 0 26px;
    background: #00745f;
  }
`;
export const StyledContainer = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 50px;
  box-sizing: border-box;
  ${(props) => props.head && "padding-bottom: 210px;"};
  @media (max-width: 720px) {
    padding: 0;
    ${(props) => props.head && "padding-bottom: 150px;"}
  }
  @media (max-width: 480px) {
    padding: 0;
    ${(props) => props.head && "padding-bottom: 40px;"}
  }
`;

export const StyledTitle = styled.h4`
  padding: 100px 0 82px;
  font-family: "Gerbera";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 48px;
  letter-spacing: -0.3px;
  color: #ffffff;
  @media (max-width: 870px) {
    padding: 48px 0 26px;
    line-height: 160%;
    font-weight: 900;
    font-size: 36px;
  }
  @media (max-width: 640px) {
    font-size: 24px;
  }
`;

export const StyledAccordionContainer = styled.div`
  margin-bottom: 82px;
  @media (max-width: 640px) {
    margin-bottom: 26px;
  }
`;

export const StyledTelegramContainer = styled.div`
  margin-top: 40px;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 4px 80px rgba(39, 150, 83, 0.12);
  border-radius: 32px;
  cursor: pointer;
  img {
    width: 56px;
  }
  &:hover {
    p {
      color: #00745f;
    }
  }
  p {
    transition: 0.3s;
    font-family: "Gerbera";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.3px;
    color: #333d37;
    margin-left: 20px;
    span {
      white-space: nowrap;
    }
  }
  @media (max-width: 870px) {
    padding: 24px;
    border-radius: 16px;
    p {
      font-weight: 700;
      font-size: 26px;
      line-height: 30px;
    }
  }
  @media (max-width: 640px) {
    p {
      font-size: 20px;
    }
  }
  @media (max-width: 680px) {
    flex-direction: column;
    align-items: flex-start;
    p {
      margin: 20px 0 0 0;
    }
  }
`;
