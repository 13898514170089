import React, { useState } from "react";
import { ReactComponent as ArrowDown } from "../../assets/svg/arrow-down.svg";
import { ReactComponent as ArrowUp } from "../../assets/svg/arrow-up.svg";
import {
  StyledAccordionContainer,
  StyledContainer,
  StyledTelegramContainer,
} from "./AnswersSectionStyle";
// import { AccordionItem } from "../../elements";
// import { data } from "./AnswerAccordionData";
import gtag from "ga-gtag";
import ym from "react-yandex-metrika";
import TgIcon from "../../assets/img/tg-icon.png";
import { AccordionItem } from "../../elements";
import getQuestions from "./questions";

const LIMIT = 5;
export const AnswerSection = () => {
  const [showAll, setShowAll] = useState(false);
  const handleClickTelegram = () => {
    gtag("event", "click_button", {
      button: "tg",
    });
    ym("reachGoal", "tg");
    window.open("https://t.me/wechek_sale");
  };

  let data = getQuestions();
  const totalQuestions = data.length;
  // let dataLeft = [];
  if (!showAll) {
    // dataLeft = data.slice(0, );
    data = data.slice(0, LIMIT);
  }
  const answersLeft = totalQuestions - LIMIT;
  return (
    <div
      style={{
        background:
          "radial-gradient(ellipse at 30% 50%, rgb(0, 134, 110) 0%, rgb(0, 100, 82) 100%)",
      }}
    >
      <div className="section">
        <StyledContainer className="lg:!px-0">
          <h4 className="!text-white pb-6 mb-4 lg:pb-0 lg:mb-14">
            Часто задаваемые вопросы
          </h4>
          <div className="mb-8 border-b border-[#ffffff1f] block lg:hidden"></div>
          <StyledAccordionContainer>
            {data.map((item, index) => {
              return (
                <AccordionItem
                  key={index}
                  index={index}
                  title={item.question}
                  content={item.answer}
                  visible={showAll || index <= LIMIT}
                />
              );
            })}
            {!showAll ? (
              <Button
                onClick={() => setShowAll(true)}
                icon={<ArrowDown width={14} />}
              >
                Показать еще {answersLeft} вопросов
              </Button>
            ) : (
              <Button
                onClick={() => setShowAll(false)}
                icon={<ArrowUp width={14} />}
              >
                Показать меньше вопросов
              </Button>
            )}
          </StyledAccordionContainer>
          <StyledTelegramContainer onClick={handleClickTelegram}>
            <img src={TgIcon} alt="no img"></img>
            <p>
              Остались вопросы? Задайте в Telegram <span>чате ›</span>
            </p>
          </StyledTelegramContainer>
        </StyledContainer>
      </div>
    </div>
  );
};

const Button = ({ children, onClick, icon }) => {
  return (
    <button
      className="w-full text-lg border border-white rounded-lg p-4 text-white flex items-center justify-center gap-4"
      onClick={onClick}
    >
      {children} {icon}
    </button>
  );
};
