import React from "react";
import { addUTMParametersToUrl } from "../../utils/utm";
import { StyledButton } from "../PrimaryButton/PrimaryButtonStyle";

export const PrimaryButtonLink = ({
  children,
  // icon,
  href,
  font,
  style,
  useFit,
  subText,
  tag,
  tag2,
}) => {
  const onClick = () => {
    if (tag) {
      tag();
    }
    if (tag2) {
      tag2();
    }
    window.open(addUTMParametersToUrl(href), "_blank");
  };
  return (
    <div className={`flex w-full  flex-col items-center`}>
      <StyledButton font={font} onClick={onClick} style={style} useFit={useFit}>
        {/*{icon && <img src={icon} alt="no img"></img>}*/}
        <span>{children}</span>
      </StyledButton>
      <div className="mt-2">{subText}</div>
    </div>
  );
};
