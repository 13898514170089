import styled from "styled-components";

export const ExtScreenshotsStyledButton = styled.button`
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 100px;
  background-color: #fff;
  padding: 14px 37px;
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 31px;
  text-align: center;
  color: #303f58;
  cursor: pointer;
  transition: 0.3s;
  width: fit-content;
  &:hover {
    color: #00745f;
    background-image: linear-gradient(87.6deg, #f0f0f0 0.11%, #ffffff 100%);
  }
  @media (max-width: 580px) {
    font-size: 16px;
    width: 100%;
    border-radius: 10px;
    margin-top: 20px;
  }
`;
