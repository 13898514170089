import gtag from "ga-gtag";
import React from "react";
import ym from "react-yandex-metrika";
import Fire from "../../assets/img/fire3.png";
import { EXTENSION_URL } from "../../utils";
import { addUTMParametersToUrl } from "../../utils/utm";
import { PrimaryButton } from "../PrimaryButton/PrimaryButton";
import DetailInfo from "./DetailInfo";
import {
  StyledBasicPrice,
  StyledButtonContainer,
  // StyledRulesText,
  StyledDataAvailibleContainer,
  StyledPriceContainer,
  StyledRateContainer,
  StyledRateName,
  StyledRatePrice,
  StyledRecommend,
  StyledSecondaryRateButton,
  StyledTitleContainer,
} from "./RateItemStyle";

export const RateItem = ({
  id,
  detailArray,
  recommend,
  title,
  price,
  monthlyPrice,
  period,
  extBlock,
  noDevider,
  previusPrice,
  // description,
  hotPrice,
  externalContent,
  expandedKeys,
  setExpandedKeys,
}) => {
  const handleClickStart = (title, isExt = false) => {
    if (isExt) {
      window.open(EXTENSION_URL, "_blank");
      return false;
    }
    if (title === "Expert") {
      gtag("event", "click_button", {
        button: "start_now_2",
      });
      ym("reachGoal", "start_now_2");
    }
    if (title === "Basic") {
      gtag("event", "click_button", {
        button: "start_now_1",
      });
      ym("reachGoal", "start_now_1");
    }
    if (title === "Pro") {
      gtag("event", "click_button", {
        button: "start_now_3",
      });
      ym("reachGoal", "start_now_3");
    }
    window.open(addUTMParametersToUrl("https://go.wecheck.ru/signup"));
  };

  return (
    <StyledRateContainer
      id={id}
      noDevider={noDevider}
      previusPrice={previusPrice}
      recommend={recommend}
      hotPrice={hotPrice}
    >
      <div>
        <StyledTitleContainer>
          <div style={{ display: "flex" }}>
            {hotPrice && (
              <div
                style={{
                  display: "flex",
                  color: "#FF0000",
                  fontSize: 12,
                  lineHeight: "21px",
                }}
              >
                🔥 СУПЕР-ПРЕДЛОЖЕНИЕ
              </div>
            )}
            {recommend ? (
              <StyledRecommend>Рекомендуем</StyledRecommend>
            ) : hotPrice ? (
              <></>
            ) : (
              <div style={{ height: 22 }} />
            )}
          </div>
          <StyledPriceContainer>
            <StyledRateName>{title}</StyledRateName>
            <>
              {previusPrice && (
                <StyledBasicPrice>{`${previusPrice.toLocaleString()}`}</StyledBasicPrice>
              )}
              {price && (
                <StyledRatePrice>
                  {price === "Бесплатно"
                    ? "Бесплатно"
                    : `${price.toLocaleString()} ₽`}
                </StyledRatePrice>
              )}
            </>
          </StyledPriceContainer>

          <div className="text-right text-xs h-3 text-gray-500">
            {monthlyPrice && <>{monthlyPrice} ₽/МЕС</>}
          </div>
          {/*{description ? <StyledRulesText>{description}</StyledRulesText> : <div className="h-[21px]" />}*/}
        </StyledTitleContainer>
        <StyledDataAvailibleContainer>
          {period}
          {/* <div style={{ width: "15px", height: "18px" }}>
            <StyledPopupTooltip
              trigger={(open) => <Info />}
              position="bottom center"
              closeOnDocumentClick
              on={["hover", "focus"]}
            >
              <StyledPopupContent>
                Какой то текст для подсказки будет идти тут, его должен кто то
                написать
              </StyledPopupContent>
            </StyledPopupTooltip>
          </div> */}
        </StyledDataAvailibleContainer>
        {extBlock && (
          <StyledDataAvailibleContainer style={{ marginTop: 26 }}>
            {extBlock}
          </StyledDataAvailibleContainer>
        )}
        <div className="space-y-3">
          {detailArray.map((detail) => (
            <DetailInfo
              detailInfo={detail}
              key={detail.label}
              expandedKeys={expandedKeys}
              isExpanded={
                !detail.expandable || expandedKeys.includes(detail.label)
              }
              setExpandedKeys={setExpandedKeys}
            />
          ))}
        </div>
        <div>{externalContent}</div>
        <StyledButtonContainer>
          {recommend ? (
            <PrimaryButton
              font="Gerbera"
              popupDisable={true}
              onClick={() => handleClickStart(title)}
              icon={Fire}
              style={{ width: "100%" }}
            >
              Начать прямо сейчас
            </PrimaryButton>
          ) : (
            <StyledSecondaryRateButton
              onClick={() => handleClickStart(title, id === "ext")}
            >
              {id === "ext" ? "Установить в браузер" : "Начать прямо сейчас"}
            </StyledSecondaryRateButton>
          )}
        </StyledButtonContainer>
      </div>
    </StyledRateContainer>
  );
};
