export const apiUrl = "https://check.wecheck.ru/api";
// export const apiUrl = "http://localhost:8000/api";

export const EXTENSION_URL =
  "https://chromewebstore.google.com/detail/wecheck/nbhkkkflcfheikkjmcphbfhbleblccgl";

export const EMAIL = "info@wecheck.ru";

export const YA_ID = 54208747;
export const GA_ID = "G-GSREK1D7HK";
